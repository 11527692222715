import React from 'react';
import Svg from 'common/Svg/Svg';

import styles from './DocumentImage.module.css';

const DocumentImage = ({ imageData, className, onDelete }) => {

    const onDeleteWrapper = (e) => {
        e.stopPropagation();
        onDelete({ image: imageData });
    };

    const containerClasses = className ? [styles.container, className].join(' ') : styles.container;

    const imageSource = imageData.content.data.slice(0,4) === 'blob'
        ? imageData.content.data
        : `data:image;base64,${imageData.content.data}`;

    return (
        <div className={containerClasses}>

            <div className={styles.overlayControls}>
                <div className={styles.topBar}>
                    <button className={styles.deleteButton} onClick={onDeleteWrapper}>
                        <Svg className={styles.svg} name="icon-trashcan" size="30" />
                    </button>
                </div>
            </div>

            <img
                className={styles.image}
                src={imageSource}
                alt="temp"
            />
        </div>
    );
};

export default DocumentImage;
