import React from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import auth0Client from '../../Auth';
import Icon from '../../icons'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import { Trans } from 'react-i18next';

import { NavLink } from 'react-router-dom'

function NavBar(props) {

  const signOut = () => {
    auth0Client.signOut();
    props.history.replace('/');
  }

	const displayUserImage = () => {
		$('.userimage').css(
			'background-image',
			'url(' + auth0Client.getProfile().picture + ')'
		);
	}

  return (
		<Navbar>
		<nav>
    	<Navbar.Brand href = "/patients" >
		{
      		<img src="/images/emdee-brand.png" alt="DH" height="70" width="70" style={{marginLeft: 30 + 'px'}}/>
    	}
		</Navbar.Brand>

		{
			<Nav variant="tabs" defaultActiveKey= {auth0Client.isAuthenticated() ? "#patients" : "#quiz"} >
    		{ auth0Client.isAuthenticated() &&
				<Nav.Item>
					<NavLink to="/patients">
						<Trans>Patients</Trans>
					</NavLink>
				</Nav.Item>
			}
			{ auth0Client.isAuthenticated() &&
				<Nav.Item>
					<NavLink to="/records">
						<Trans>Medical records</Trans>
					</NavLink>
				</Nav.Item>
			}
			<Nav.Item>
    			<NavLink to="/quiz">
					<Trans>Skin Lesion Quiz</Trans>
				</NavLink>
    		</Nav.Item>
			</Nav>
		}

		{
      	 auth0Client.isAuthenticated() &&
      	<div id="loggedInUser" onClick = { () => {
					$('.account-dropdown , #loggedInUser').toggleClass('collapsed');
					displayUserImage();
 				} } >
        	<span className = "userimage"></span>
					<span className = "username">
						{ auth0Client.getProfile().name }
					</span>
          <Icon icon="caret" size="30"/>
				</div>
    	}

			{
				 !auth0Client.isAuthenticated() &&
				<Navbar.Collapse >
					<Button onClick = { auth0Client.signIn }>
						<Trans>Sign In</Trans>
					</Button>
				</Navbar.Collapse>
			}
		</nav>
		{
			 auth0Client.isAuthenticated() &&
			<ul className="account-dropdown collapsed">
				<li onClick = { () => { signOut() } }>
					<Trans>Sign Out</Trans>
				</li>
			</ul>
		}
		</Navbar>
  );

}


export default withRouter(NavBar);
