import React, { useEffect, useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';
import Button from 'common/Button/Button';
import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { useRedirect } from 'common/common.hooks';
import { usePatient } from 'modules/Patients/Patients.hooks';
import { PatientsContext } from 'modules/Patients/Patients.context';

import styles from './PatientDetails.module.css';

// ------------------------------------------------------------------------------------------------------------------------- PatientDetails component --

const PatientDetails = ({ match, location, history }) => {
    const { patientId } = match.params;

    const { patientsState, patientsActn } = useContext(PatientsContext);
    const [redirectState, redirectActn] = useRedirect();
    const [patientState, patientActn] = usePatient();

    const onSave = async () => {
        if (patientId) {
            const patientData = await patientActn.updatePatient(patientState.patient);
            NotificationManager.success(patientData.name, i18next.t('Patient saved'));
            redirectActn.setLocation(`/patients/${patientData.id}`);
        } else {
            const patientData = await patientActn.createPatient(patientState.patient);
            NotificationManager.success(patientData.name, i18next.t('Patient added'));
            redirectActn.setLocation(`/patients/${patientData.id}`);

        }
    };

    const onCancel = () => {
        const redirectLocation = patientId ? `/patients/${patientId}` : `/patients`;
        redirectActn.setLocation(redirectLocation);
    };

    useEffect(() => {
        if (patientId) {
            patientActn.getPatientById(patientId);
        } else if (!!patientsState.latestSearchValue) {
            patientActn.updateName(patientsState.latestSearchValue);
            patientsActn.clearLatestSearchValue();
        }
    }, []);

    return redirectState.location
        ? <Redirect to={redirectState.location} push />
        : patientState.getPatientPending
        ? <LoadingIndicator />
        : (
        <ScreenContent>
            <ScreenTitle>
                { patientId ? patientState.patient.name : i18next.t('Add patient') }
            </ScreenTitle>

            <ScreenControls className={styles.controlsContainer}>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation('/patients')}>
                        <Trans>Patients</Trans>
                    </BreadcrumbItem>
                    { patientId ?
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(`/patients/${patientId}`)}>
                        { patientState.patient.name }
                    </BreadcrumbItem> : null }
                </Breadcrumb>
                <div>
                    <Button className={styles.button} onClick={e => onCancel()}>
                        <Trans>Discard</Trans>
                    </Button>
                    <Button className={styles.button} primary onClick={e => onSave()}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </ScreenControls>

            <label className={styles.label} htmlFor="name"><Trans>Identifier</Trans></label>
            <textarea
                className={styles.textarea}
                name="name"
                placeholder={i18next.t("Any unique identifier such as name, SSN, date of birth, passport no…")}
                value={patientState.patient.name}
                onChange={e => patientActn.updateName(e.target.value)}
            />

            <label className={styles.label} htmlFor="description"><Trans>Additional information</Trans></label>
            <textarea
                className={styles.textarea}
                name="description"
                placeholder={i18next.t("Additional info, e.g. date of birth, gender, weight…")}
                value={patientState.patient.description}
                onChange={e => patientActn.updateDescription(e.target.value)}
            />
        </ScreenContent>
        );
};

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default withRouter(PatientDetails);
