import React, { useEffect } from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom'

import { useRedirect } from 'common/common.hooks';
import { PatientsProviderWrapper } from './Patients.context';

import PatientList from './PatientList/PatientList';
import Patient from './Patient/Patient';
import PatientDetails from './Patient/PatientDetails/PatientDetails';

const Patients = ({ match, location, history }) => {
    const [redirectState, redirectActn] = useRedirect();

    return redirectState.location ? <Redirect to={redirectState.location} push /> : (
        <PatientsProviderWrapper>
            <Switch>
                <Route exact path={`${match.path}`} component={PatientList} />
                <Route exact path={`${match.path}/create`} component={PatientDetails} />
                <Route exact path={`${match.path}/:patientId`} component={Patient} />
                <Route exact path={`${match.path}/:patientId/details`} component={PatientDetails} />
            </Switch>
        </PatientsProviderWrapper>
    );
};

export default withRouter(Patients);
