import axios from 'axios';
import auth0Client from '../Auth';

const visitsUrl = '/webapi/visits/';

class VisitRepo {
	constructor() {
		this.listByPatientId = this.listByPatientId.bind(this);
		this.getById = this.getById.bind(this);
		this.saveVisit = this.saveVisit.bind(this);
	}
	
	list(callBack) {	
	  this._getFromApi(visitsUrl, callBack);
	}
	
	listByPatientId(value, callBack) {	
		const url = visitsUrl + ((value === '') ? '' : 'patient/' + value + '/');
		this._getFromApi(url, callBack);
	}
	
	getById(id, callBack) {	
		const url = visitsUrl + 'id/' + id + '/';
		this._getFromApi(url, callBack);
	}

    deleteById(id, callBack) {	
		const url = visitsUrl + 'id/' + id + '/';
		this._deleteFromApi(url, callBack);
	}

	saveVisit(visitDto){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		const formData = new FormData();
		if (visitDto.mdId){
			formData.append('mdId', visitDto.mdId);
		} else {
			formData.append('mdId', -1);
		}
		formData.append('patientId', visitDto.patientId);
		formData.append('patientAgreement', visitDto.patientAgreement);
		formData.append('uptake', visitDto.uptake);
		formData.append('documentIds', visitDto.documentIds);
		formData.append('documentContentIds', visitDto.documentContentIds);
		for (var i = 0; i < visitDto.documentContents.length; i++){
			formData.append('documentContents', visitDto.documentContents[i]);
		}
		for (i = 0; i < visitDto.medicalRecords.length; i++){
			for (var j = 0; j < visitDto.medicalRecords[i].images.length; j++){
				formData.append('medicalRecordImages', visitDto.medicalRecords[i].images[j]);
			}
			visitDto.medicalRecords[i].numberOfDocs = visitDto.medicalRecords[i].images.length;
			delete visitDto.medicalRecords[i].images;
			formData.append('medicalRecords', JSON.stringify(visitDto.medicalRecords[i]));
		}
		formData.append('medicalRecords', JSON.stringify({isTailElement: true}));
                
		if (visitDto.id){
			return axios.put(visitsUrl + 'id/' + visitDto.id + '/', formData, { headers: { Authorization: authStr } });
		} else {
			return axios.post(visitsUrl, formData, { headers: { Authorization: authStr } });
		}
	}

	_getFromApi(url, callBack){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		axios.get(url,{ headers: { Authorization: authStr } }).then(
            (response) => {                
				const Visits = response.data;
				callBack(Visits);
		  }
	  )
	}
    
    _deleteFromApi(url, callBack) {
        const authStr = 'Bearer '.concat(auth0Client.accessToken);
		axios.delete(url,{ headers: { Authorization: authStr } }).then(
            (response) => {                
				const Visits = response.data;
				callBack(Visits);
		  }
	  )
    }

}

const visitRepo = new VisitRepo();

export default visitRepo;