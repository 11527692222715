import axios from 'axios';
import auth0Client from '../Auth';

const predictionsUrl = '/webapi/predictions/';

class PredictionRepo {
	constructor() {
		this.predictByMicroImage = this.predictByMicroImage.bind(this);
        this.predictByMacroImage = this.predictByMacroImage.bind(this);
	}

	predictByMicroImage(microImage, index, groupIndex, callBack){
        this.predictByImage(microImage, "micro", index, groupIndex, callBack)
	}

    predictByMacroImage(macroImage, index, groupIndex, callBack){
        this.predictByImage(macroImage, "macro", index, groupIndex, callBack)
	}

    predictByImage(image, kind, index, groupIndex, callBack){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		const formData = new FormData();
		if (image.content.id){
			formData.append('contentid', image.content.id);
		} else {
			formData.append('image', image.file);
		}
		axios.post(predictionsUrl + kind, formData, { headers: { Authorization: authStr } })
			.then((response) => {
				const predictionResult = response.data;
				callBack(predictionResult, index, groupIndex);
			})
			.catch((error) => {
				callBack(null, index, groupIndex);
			})
	}
}

const predictionRepo = new PredictionRepo();

export default predictionRepo;