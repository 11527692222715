import { useState } from 'react';
import visitSrvc from './Visit.srvc';

// ------------------------------------------------------------------------------------------------------------------------------------------ helpers --

function createNormalizedList(list) {

    const byId = list.reduce((itemsById, currentItem) => {
        itemsById[currentItem.id] = currentItem;
        return itemsById;
    }, {});

    const allIds = list.map(currentItem => currentItem.id);

    return { byId, allIds };
};

// ---------------------------------------------------------------------------------------------------------------------------------- visit hook init --

const visitInitialState = {
    visit: {
        id: null,
        date: new Date(),
        permission: 0,
        mdId: null,

        documents: [],
        newDocuments: [],

        medicalRecords: [],
        newMedicalRecords: [],
    },

    getVisitPending: false,
    getVisitError: false,

    saveVisitPending: false,
    saveVisitSuccess: false,
    saveVisitError: false,

    deleteVisitPending: false,
    deleteVisitSuccess: false,
    deleteVisitError: false,

    documents: {
        byId: {},
        allIds: [],
    },

    medicalRecords: {
        byId: {},
        allIds: [],
    },

    medicalRecordImages: {
        byId: {},
        allIds: [],
    },
};

// --------------------------------------------------------------------------------------------------------------------------------------- visit hook --

// TODO: refactor to multiple reducers, consider adding redux

function useVisit() {
    const [state, setState] = useState(visitInitialState);

    // -------------------------------------------------------------------------------------------------------------------------------- visit actions --

    async function saveVisit({ visit }) {
        setState(prevState => ({ ...prevState, saveVisitPending: true, saveVisitError: false }));

        try {
            const response = await visitSrvc.saveVisit(visit);
            console.log('save visit response', response);

            setState(prevState => ({
                ...prevState, saveVisitPending: false, saveVisitError: false, saveVisitSuccess: true,
            }));

        } catch {
            setState(prevState => ({ ...prevState, saveVisitPending: false, saveVisitError: true }));
        }
    }

    async function getVisitById(id) {
        setState(prevState => ({ ...prevState, getVisitPending: true, getVisitError: false }));

        try {
            const {
                visit,
                documents,
                medicalRecords,
                mdRecordImages,
            } = await visitSrvc.getVisitById(id);

            const normalizedDocumentsList = createNormalizedList(documents);
            const normalizedMDRecordsList = createNormalizedList(medicalRecords);
            const normalizedMDRerodImagesList = createNormalizedList(mdRecordImages);

            setState(prevState => ({
                ...prevState,
                visit,
                getVisitPending: false,
                documents: normalizedDocumentsList,
                medicalRecords: normalizedMDRecordsList,
                medicalRecordImages: normalizedMDRerodImagesList,
            }));
        } catch {
            setState(prevState => ({ ...prevState, getVisitPending: false, getVisitError: true }));
        }
    };

    async function deleteVisit({ id }) {
        setState(prevState => ({ ...prevState, deleteVisitPending: true, deleteVisitError: false, deleteVisitSuccess: false }));

        try {
            const response = await visitSrvc.deleteVisitById(id);
            console.log('delete visit response', response);
            setState(prevState => ({ ...prevState, deleteVisitPending: false, deleteVisitError: false, deleteVisitSuccess: true }));
        } catch {
            setState(prevState => ({ ...prevState, deleteVisitPending: false, deleteVisitError: true, deleteVisitSuccess: false }));
        }
    }

    // ---------------------------------------------------------------------------------------------------------------------------- documents actions --

    function addDocument({ document }) {
        const newByIdObject = {
            [document.id]: document,
        };

        setState(prevState => {
            const { visit, documents } = prevState;

            const updatedDocuments = {
                byId: { ...documents.byId, ...newByIdObject },
                allIds: [ ...documents.allIds, document.id ],
            };

            const updatedVisit = {
                ...visit,
                newDocuments: [ ...visit.newDocuments, document.id ],
            };

            return {
                ...prevState,
                visit: updatedVisit,
                documents: updatedDocuments,
            }
        });
    }

    function updateDocuments({ documents, newDocuments }) {
        const normalizedDocumentList = createNormalizedList(documents);
        const documentIds = normalizedDocumentList.allIds.map(id => id);
        const normalizedNewDocumentList = createNormalizedList(newDocuments);
        const newDocumentIds = normalizedNewDocumentList.allIds.map(id => id);

        setState(prevState => {
            const { visit } = prevState;

            const updatedDocuments = {
                byId: { ...normalizedDocumentList.byId, ...normalizedNewDocumentList.byId },
                allIds: [ ...normalizedDocumentList.allIds, ...normalizedNewDocumentList.allIds ],
            };

            const updatedVisit = {
                ...visit,
                documents: documentIds,
                newDocuments: newDocumentIds,
            };

            return {
                ...prevState,
                visit: updatedVisit,
                documents: updatedDocuments,
            }
        });
    };

    function removeDocument({ id }) {
        setState(prevState => {
            const { visit } = prevState;

            const updatedDocuments = visit.documents.filter(currentId => currentId !== id);
            const updatedNewDocuments = visit.newDocuments.filter(currentId => currentId !== id);

            const newVisit = {
                ...visit,
                documents: updatedDocuments,
                newDocuments: updatedNewDocuments,
            };

            return {
                ...prevState,
                visit: newVisit,
            };
        });
    };

    // ---------------------------------------------------------------------------------------------------------------------------- mdRecords actions --

    function addMedicalRecord({ medicalRecord, images }) {
        console.log('[visit hooks] - add medical record');
        console.log('medicalRecord', medicalRecord);
        console.log('new images', images);
        console.log('[visit hooks] - add medical record');

        const normalizedMDRerodImagesList = createNormalizedList(images);

        setState(prevState => {
            const { visit, medicalRecords, medicalRecordImages } = prevState;

            const updatedMDRecords = {
                byId: {
                    ...medicalRecords.byId,
                    [medicalRecord.id]: medicalRecord,
                },
                allIds: [ ...medicalRecords.allIds, medicalRecord.id ],
            };

            const updatedVisit = {
                ...visit,
                newMedicalRecords: [ ...visit.newMedicalRecords, medicalRecord.id ],
            };

            const updatedMDRecordImages = {
                byId: { ...medicalRecordImages.byId, ...normalizedMDRerodImagesList.byId },
                allIds: [ ...medicalRecordImages.allIds, ...normalizedMDRerodImagesList.allIds ],
            };

            return {
                ...prevState,
                visit: updatedVisit,
                medicalRecords: updatedMDRecords,
                medicalRecordImages: updatedMDRecordImages,
            }
        });
    };

    function removeMedicalRecord({ id }) {
        setState(prevState => {
            const { visit } = prevState;

            const updatedMedicalRecors = visit.medicalRecords.filter(currentId => currentId !== id);
            const updatedNewMedicalRecors = visit.newMedicalRecords.filter(currentId => currentId !== id);

            const newVisit = {
                ...visit,
                medicalRecords: updatedMedicalRecors,
                newMedicalRecords: updatedNewMedicalRecors,
            };

            return {
                ...prevState,
                visit: newVisit,
            };
        });
    };

    function updateMedicalRecord({ medicalRecord, images }) {
        console.log('[visit hooks] - update medical record');
        console.log('medicalRecord', medicalRecord);
        console.log('new images', images);
        console.log('[visit hooks] - update medical record');

        const normalizedMDRerodImagesList = createNormalizedList(images);

        setState(prevState => {
            const { medicalRecords, medicalRecordImages } = prevState;

            const imageIds = [...medicalRecordImages.allIds, ...normalizedMDRerodImagesList.allIds];
            const distinctIds = imageIds.filter((item, index) => imageIds.indexOf(item) === index);

            console.log('------------- all image ids', imageIds);
            console.log('------------- distinct image ids', distinctIds);

            const updatedMDRecordImages = {
                byId: { ...medicalRecordImages.byId, ...normalizedMDRerodImagesList.byId },
                allIds: distinctIds,
            };

            return {
                ...prevState,
                medicalRecords: {
                    allIds: [...medicalRecords.allIds],
                    byId: {
                        ...medicalRecords.byId,
                        [medicalRecord.id]: medicalRecord,
                    },
                },
                medicalRecordImages: updatedMDRecordImages,
            };
        });
    };

    // TODO: remove later
    function addImagesToMDRecord({ images, medicalRecordId }) {
        const normalizedMDRerodImagesList = createNormalizedList(images);
        const newImageIds = normalizedMDRerodImagesList.allIds.map(imageId => imageId);

        setState(prevState => {
            const { medicalRecords, medicalRecordImages } = prevState;
            const prevMDRecord = medicalRecords.byId[medicalRecordId];

            const updatedMDRecord = {
                ...prevMDRecord,
                documents: [ ...prevMDRecord.documents, ...newImageIds ],
            };

            const updatedMDRecordImages = {
                byId: { ...medicalRecordImages.byId, ...normalizedMDRerodImagesList.byId },
                allIds: [ ...medicalRecordImages.allIds, ...normalizedMDRerodImagesList.allIds ],
            };

            return {
                ...prevState,
                medicalRecords: {
                    allIds: [...medicalRecords.allIds],
                    byId: {
                        ...medicalRecords.byId,
                        [medicalRecordId]: updatedMDRecord,
                    },
                },
                medicalRecordImages: updatedMDRecordImages,
            };
        });
    }

    function updateTherapist({ id }) {
        const { visit } = state;

        setState(prevState => ({
            ...prevState,
            visit: {
                ...visit,
                mdId: id,
            },
        }));
    }

    function updatePermission({ permission }) {
        const { visit } = state;

        setState(prevState => ({
            ...prevState,
            visit: {
                ...visit,
                permission,
            },
        }));
    }

    function updateDate({ date }) {
        const { visit } = state;

        setState(prevState => ({
            ...prevState,
            visit: {
                ...visit,
                date,
            },
        }));
    }

    // -------------------------------------------------------------------------------------------------------------------------------------- exports --

    return [ state, {
        getVisitById,
        saveVisit,
        deleteVisit,

        addDocument,
        removeDocument,
        updateDocuments,

        addMedicalRecord,
        removeMedicalRecord,
        addImagesToMDRecord,
        updateMedicalRecord,

        updateTherapist,
        updatePermission,
        updateDate,
    } ];
};

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export { useVisit };
