import React from 'react';
import { Trans } from 'react-i18next';

import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';

// TEMPORARY SOLUTION!!

const LoadingIndicator = () => (
    <ScreenContent>
        <ScreenTitle>
            <Trans>Loading...</Trans>
        </ScreenTitle>
    </ScreenContent>
);

export default LoadingIndicator;
