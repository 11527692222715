import { useState } from 'react';
import usersSrvc from 'common/users.srvc';

function useRedirect() {
    const [state, setState] = useState({ location: '' });
    const setLocation = (location) => setState({ location });

    return [state, { setLocation }];
}

function useUsers() {
    const [state, setState] = useState({
        users: [],
        newTherapistName: null,
        getUsersPending: false,
        getUsersError: false,
    });

    async function getUsers() {
        setState(prevState => ({ ...prevState, getUsersPending: true, getUsersError: false }));

        try {
            const response = await usersSrvc.getUsers();
            setState(prevState => ({ ...prevState, getUsersPending: false, getUsersError: false, users: response }));
        } catch {
            setState(prevState => ({ ...prevState, getUsersPending: false, getUsersError: true }));
        }
    }
    
    function addUser(therapistName) {   
        setState(prevState => ({ ...prevState, newTherapistName: therapistName}))        
    }

    return [state, { getUsers, addUser }];
}

export { useRedirect, useUsers };   
