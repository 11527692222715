import { useState } from 'react';
import patientsSrvc from 'modules/Patients/Patients.srvc';

// ------------------------------------------------------------------------------------------------------------------------------------- use patients --

function usePatients() {
    const [state, setState] = useState({
        patients: [],
        getPatientsPending: false,
        getPatientsError: false,
        latestSearchValue: null,
    });

    async function getPatients() {
        setState(prevState => ({ ...prevState, getPatientsPending: true, getPatientsError: false }));

        try {
            const response = await patientsSrvc.getPatients();
            setState(prevState => ({ ...prevState, getPatientsPending: false, patients: response }));
        } catch {
            setState(prevState => ({ ...prevState, getPatientsPending: false, getPatientsError: true }));
        }
    }

    async function searchByName(name) {
        const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        setState(prevState => ({ ...prevState, getPatientsPending: true, getPatientsError: false, latestSearchValue: escapedName }));

        try {
            const response = await patientsSrvc.searchByName(escapedName);
            setState(prevState => ({ ...prevState, getPatientsPending: false, patients: response }));
        } catch {
            setState(prevState => ({ ...prevState, getPatientsPending: false, getPatientsError: true }));
        }
    }

    function clearLatestSearchValue() {
        setState(prevState => ({ ...prevState, latestSearchValue: null }));
    }

    return [state, {
        getPatients,
        searchByName,
        clearLatestSearchValue,
    }];
}

// ------------------------------------------------------------------------------------------------------------------------------------- use patients --

function usePatient() {
    const [state, setState] = useState({
        patient: { name: '', description: '' },
        getPatientPending: false,
        getPatientError: false,
        createPatientPending: false,
        createPatientError: false,
        createPatientSuccess: false,
        updatePatientPending: false,
        updatePatientError: false,
        updatePatientSuccess: false,
    });

    const getPatientById = async (patientId) => {
        setState(prevState => ({ ...prevState, getPatientPending: true, getPatientError: false }));

        try {
            const response = await patientsSrvc.getPatientById(patientId);
            setState(prevState => ({ ...prevState, getPatientPending: false, patient: response }));
        } catch {
            setState(prevState => ({ ...prevState, getPatientPending: false, getPatientError: true }));
        }
    };

    const createPatient = async (patient) => {
        setState(prevState => ({ ...prevState, createPatientPending: true, createPatientError: false }));

        try {
            const response = await patientsSrvc.createPatient(patient);
            console.log('create success', response);

            setState(prevState => ({
                ...prevState, createPatientPending: false, createPatientSuccess: true, patient: response,
            }));

            return response;
        } catch {
            setState(prevState => ({ ...prevState, createPatientPending: false, createPatientError: true }));
        }
    };

    const updatePatient = async (patient) => {
        setState(prevState => ({ ...prevState, updatePatientPending: true, updatePatientError: false }));

        try {
            const response = await patientsSrvc.updatePatient(patient);
            console.log('update success', response);

            setState(prevState => ({
                ...prevState, updatePatientPending: false, updatePatientSuccess: true, patient: response,
            }));

            return response;
        } catch {
            setState(prevState => ({ ...prevState, updatePatientPending: false, updatePatientError: true }));
        }
    };

    const updateName = (newName) => {
        const updatedPatient = { ...state.patient, name: newName };
        setState(prevState => ({ ...prevState, patient: updatedPatient }));
    }

    const updateDescription = (newDescription) => {
        const updatedPatient = { ...state.patient, description: newDescription };
        setState(prevState => ({ ...prevState, patient: updatedPatient }));
    }

    return [state, {
        getPatientById,
        createPatient,
        updatePatient,
        updateName,
        updateDescription,
    }];
}

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export { usePatients, usePatient };
