import React from 'react';
import { Trans } from 'react-i18next';
import ImageGalery from 'common/ImageGalery/ImageGalery';

import { useFullscreenGalery, NewFullscreenGalery } from 'common/ImageGalery/FullscreenGalery';
import ImageItem from 'modules/Visit/~common/ImageItem/ImageItem';

import Button from 'common/Button/Button';
import styles from './Documents.module.css';

const Documents = ({ documentsData, onEdit }) => {
    const [ fsGaleryState, fsGaleryActn ] = useFullscreenGalery();

    return (
        <div className={styles.container}>
            <div className={styles.titlebarContainer}>
                <span className={styles.textTitle}><Trans>Documents</Trans></span>
                <Button onClick={onEdit} icon="pencil"><Trans>Edit</Trans></Button>
            </div>

            <ImageGalery images={documentsData} />

            {/* <div>
                { documentsData.map((imageItem, index) => (
                    <ImageItem
                        key={imageItem.id}
                        imageContent={imageItem.content}
                    />
                ))}
            </div>

            <NewFullscreenGalery isOpen={fsGaleryState.isOpen} fsGaleryActn={fsGaleryActn}>
                <ImageItem imageContent={documentsData[fsGaleryState.currentIndex]} />
            </NewFullscreenGalery> */}
        </div>
    );
};

export default Documents;
