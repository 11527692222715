import React from 'react';
import i18next from 'i18next';
import predictionSrvc from 'common/prediction.srvc';

import styles from './withImageDetails.module.css';

const withImageDetails = WrappedComponent => (props) => {
    const {
        imageData, ...wrappedComponentProps
    } = props;

    const predictions = imageData.predictionResult ? imageData.predictionResult.allPredictions : {};
    const sortedPredictions = predictionSrvc.sortPredictions(predictions);

    const primaryPrediction = sortedPredictions.pop();
    const secondaryPrediction = sortedPredictions.pop();

    const primaryPredictionText = primaryPrediction ?
        `${i18next.t("bnoShort:" + primaryPrediction.type, primaryPrediction.type)} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
        `${i18next.t("bnoShort:" + secondaryPrediction.type, secondaryPrediction.type)} ${Math.round(secondaryPrediction.percent * 100)}%`: null;

    return (
        <div className={styles.container}>

            <div className={styles.topBar}>
                <span className={styles.labelFat}>
                    { primaryPredictionText }
                </span>
                <span className={styles.labelFat}>
                    { secondaryPredictionText }
                </span>
            </div>

            <WrappedComponent className={styles.wrappedImage} {...wrappedComponentProps} />

            <div className={styles.bottomBar}>
                <span className={styles.label}>
                    { imageData.type === 'skinMicroPic' ? 'micro' : 'macro' }
                </span>
            </div>

        </div>
    );
}

export default withImageDetails;
