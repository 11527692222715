import axios from 'axios';
import qs from 'querystringify';
import auth0Client from '../Auth';

const medicalRecordsUrl =  '/webapi/medicalrecords/';

class MedicalRecordRepo {
	constructor() {
		this.list = this.list.bind(this);
		this.getById = this.getById.bind(this);
		this._getFromApi = this._getFromApi.bind(this);
	}
	
	list(callBack, searchCriteria) {	
		let url = medicalRecordsUrl;
		if (searchCriteria){
			url = url + qs.stringify(searchCriteria, true);
		}
		this._getFromApi(url, callBack);
	}

	getById(id, callBack) {	
		const url = medicalRecordsUrl + 'id/' + id + '/';
		this._getFromApi(url, callBack);		
	}

	_getFromApi(url, callBack){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		axios.get(url,{ headers: { Authorization: authStr } }).then(
		  (response) => {
				const medicalRecords = response.data;
				callBack(medicalRecords);
		  }
	  )
	}


}

const medicalRecordRepo = new MedicalRecordRepo();

export default medicalRecordRepo;