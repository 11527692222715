import userRepo from '~legacy/UserRepo';

const getUsers = () => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    userRepo.list(onSuccess);
});

const getUserById = (id) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    userRepo.getById(id, onSuccess);
});

export default {
    getUsers,
    getUserById,
};
