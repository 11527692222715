import React, { createContext } from 'react';
import { useVisit } from './Visit.hooks';
import { usePatient } from 'modules/Patients/Patients.hooks';
import { useUsers } from 'common/common.hooks';


const VisitContext = createContext();
const { Provider: VisitProvider, Consumer: VisitConsumer } = VisitContext;

const VisitProviderWrapper = ({ children, patientId, visitId }) => {

    const [visitState, visitActn] = useVisit();
    const [patientState, patientActn] = usePatient();
    const [usersState, usersActn] = useUsers();

    const providedValue = {
        visitState,
        visitActn,
        patientState,
        patientActn,
        usersState,
        usersActn,
    };

    return (
        <VisitProvider value={providedValue}>
            { children }
        </VisitProvider>
    );
};

export {
    VisitContext,
    VisitProvider,
    VisitConsumer,
    VisitProviderWrapper,
};
