import React from 'react';
import styles from './RadioButton.module.css';

const RadioButton = ({ value, checked, className, id, text, onChange }) => (
    <div className={styles.container}>
        <input className={styles.checkbox} onChange={onChange} id={id} type="radio" value={value} checked={checked} />
        <label className={styles.label} htmlFor={id} >{ text }</label>
    </div>
);

export default RadioButton;
