import axios from 'axios';
import auth0Client from '../Auth';

const imageUrl = '/webapi/images/';

class ImageRepo {
	constructor() {	
		this._getFromApi = this._getFromApi.bind(this);
		this.downloadAllByRecordId = this.downloadAllByRecordId.bind(this);
		this.downloadByIds = this.downloadByIds.bind(this);
	}
	
	downloadAllByRecordId(id) {	
		const url = imageUrl + 'record/' + id + '/';
		this._getFromApi(url, "images_" + id);
	}

	downloadByIds(ids) {	
		const url = imageUrl + 'ids/' + ids + '/';
		this._getFromApi(url, "images_" + ids);
	}

	_getFromApi(url, fileName){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		axios.get(url,{ headers: { Authorization: authStr }, responseType: 'arraybuffer' }).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName + '.zip');
			document.body.appendChild(link);
			link.click();
		});
	}
}

const imageRepo = new ImageRepo();

export default imageRepo;