import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { useRedirect } from 'common/common.hooks';
import { VisitContext } from 'modules/Visit/Visit.context';
import { useLesionRecord } from './LesionRecordEditor.hooks';

import { useFullscreenGalery, NewFullscreenGalery } from 'common/ImageGalery/FullscreenGalery';
import TextareaComponent from 'common/Textarea/Textarea';
import { longBnoCodesForDropDownAssistance } from '~legacy/bnocodes';

import Button from 'common/Button/Button';
import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';
import ImageDropzone from 'common/ImageDropzone/ImageDropzone';
import { ImageWithControlsOverlay, ImageWithControlsWrapper } from 'modules/Visit/~common/ImageItem/ImageItem';

import styles from './LesionRecordEditor.module.css';


// --------------------------------------------------------------------------------------------------------------------- LesionRecordEditor component --

const LesionRecordEditor = ({ match, location, history }) => {
    const { patientId, visitId, recordId } = match.params;
    const [ redirectState, redirectActn ] = useRedirect();
    const [ fsGaleryState, fsGaleryActn ] = useFullscreenGalery();
    const [ lesionRecordState, lesionRecordActn ] = useLesionRecord();

    const {
        visitState, visitActn, patientState, patientActn,
    } = useContext(VisitContext);

    // ----------------------------------------------------------------------------------------------------------------------------------- UI actions --

    const createRecord = () => {
        const { medicalRecord, images } = lesionRecordState;

        const redirectLocation = visitId
            ? `/patients/${patientId}/visits/${visitId}`
            : `/patients/${patientId}/visits/create`;

        visitActn.addMedicalRecord({ medicalRecord, images });
        redirectActn.setLocation(redirectLocation);
    }

    const updateRecord = () => {
        const { medicalRecord, images } = lesionRecordState;

        const redirectLocation = visitId
            ? `/patients/${patientId}/visits/${visitId}`
            : `/patients/${patientId}/visits/create`;

        visitActn.updateMedicalRecord({ medicalRecord, images });
        redirectActn.setLocation(redirectLocation);
    }

    const onSave = () => recordId
        ? updateRecord()
        : createRecord();

    const onCancel = () => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create`);

    const onDelete = () => {
        const isConfirmed = window.confirm(i18next.t('Please confirm delete operation'));

        const redirectLocation = visitId
            ? `/patients/${patientId}/visits/${visitId}`
            : `/patients/${patientId}/visits/create`;

        if (isConfirmed) {
            visitActn.removeMedicalRecord({ id: Number(recordId) });
            redirectActn.setLocation(redirectLocation);
        }
    };

    const onUpdateSuspicion = (value) => {
        lesionRecordActn.updateSuspicion({ suspicion: value });
    };

    const onUpdateDiagnosis = (value) => {
        lesionRecordActn.updateDiagnosis({ diagnosis: value });
    };

    const onAddImages = ({ imageFiles }) => {
        lesionRecordActn.addImages({ imageFiles });
    };

    // TODO: remove later
    const onRemoveImages = ({ images }) => {
        lesionRecordActn.removeImages({ images });
    };

    const onRemoveImage = ({ image }) => {
        if (fsGaleryState.isOpen) fsGaleryActn.closeGalery();
        onRemoveImages({ images: [image] });
    }

    const onUpdateImageType = ({ imageId, imageType }) => {
        lesionRecordActn.updateImageType({ imageId, imageType });
    };

    const onImageClicked = ({ event, index }) => {
        event.stopPropagation();
        fsGaleryActn.openGalery({ currentIndex: index, images: lesionRecordState.images });
    };

    // -------------------------------------------------------------------------------------------------------------------------------------- effects --

    useEffect(() => {
        if (patientId && patientState.patient.id !== Number(patientId)) patientActn.getPatientById(patientId);
        if (visitId && visitState.visit.id !== Number(visitId)) visitActn.getVisitById(visitId);
    }, []);

    useEffect(() => {
        const recordInVisitState = visitState.medicalRecords.byId[recordId];
        const { medicalRecordImages } = visitState;

        if (recordInVisitState) {
            const images = recordInVisitState.documents.map(imageId => medicalRecordImages.byId[imageId]);
            const newImages = recordInVisitState.newDocuments.map(imageId => medicalRecordImages.byId[imageId]);

            // deep copy hack to prevent mutations because reference passing
            const recordCopy = JSON.parse(JSON.stringify(recordInVisitState));

            const imagesCopy = images.map(imageItem => ({
                ...imageItem,
                content: { ...imageItem.content },
                predictionResults: { ...imageItem.predictionResults },
            }));

            const newImagesCopy = newImages.map(imageItem => ({
                ...imageItem,
                content: { ...imageItem.content },
                predictionResults: { ...imageItem.predictionResults },
            }));

            const mergedImagesCopy = [...imagesCopy, ...newImagesCopy];

            lesionRecordActn.reInitalize({ medicalRecord: recordCopy, images: mergedImagesCopy })
        }

    }, [visitState]);

    // ------------------------------------------------------------------------------------------------------------------------ prepare for rendering --

    const backToVisitLocation = visitId
        ? `/patients/${patientId}/visits/${visitId}`
        : `/patients/${patientId}/visits/create`;

    console.log('lesion record local state', lesionRecordState);

    // ------------------------------------------------------------------------------------------------------------------------------------ rendering --

    return redirectState.location
        ? <Redirect to={redirectState.location} push />
        : patientState.getPatientPending || visitState.getVisitPending
        ? <LoadingIndicator />
        : (
        <ScreenContent>
            <ScreenTitle>
                { recordId
                    ? <Trans>Skin lesion</Trans>
                    : <Trans>New skin lesion</Trans> }
            </ScreenTitle>

            <ScreenControls className={styles.controlsContainer}>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation('/patients')}>
                        <Trans>Patients</Trans>
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(`/patients/${patientId}`)}>
                        { patientState.patient.name }
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(backToVisitLocation)}>
                        <Trans>Visit</Trans>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    { recordId ?
                    <Button onClick={e => onDelete()}>
                        <Trans>Delete</Trans>
                    </Button> : null }
                    <Button className={styles.button} onClick={e => onCancel()}>
                        <Trans>Discard</Trans>
                    </Button>
                    <Button className={styles.button} primary onClick={e => onSave()}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </ScreenControls>

            <ImageDropzone onAddImages={onAddImages}>
            { lesionRecordState.images.map((imageItem, index) => (
                <ImageWithControlsOverlay
                    className={styles.imageContainer}
                    key={imageItem.id}
                    imageData={imageItem}
                    predictionPending={!!lesionRecordState.pendingPredictions.find(imageId => imageId === imageItem.id)}
                    onClick={(event) => onImageClicked({ event, index })}
                    onDelete={onRemoveImage}
                    onUpdateImageType={onUpdateImageType}

                    imageContent={imageItem.content}
                />
            ))}
            </ImageDropzone>

            <label className={styles.label} htmlFor="name"><Trans>Supposed diagnosis</Trans></label>
            <TextareaComponent
                className={styles.textarea}
                value={lesionRecordState.medicalRecord.suspicion}
                type="text"
                list={longBnoCodesForDropDownAssistance}
                placeholder={i18next.t('Use hashmark (#) for diagnosis code, e.g. #D22.')}
                maxLength="5000"
                onUpdateValue={onUpdateSuspicion}
            />

            <label className={styles.label} htmlFor="description"><Trans>Histology</Trans></label>
            <TextareaComponent
                className={styles.textarea}
                value={lesionRecordState.medicalRecord.diagnosis}
                type="text"
                list={longBnoCodesForDropDownAssistance}
                placeholder={i18next.t('Use hashmark (#) for diagnosis code, e.g. #C44. If known, set the thickness in the following format -> 0.3 mm')}
                maxLength="5000"
                onUpdateValue={onUpdateDiagnosis}
            />

            <NewFullscreenGalery isOpen={fsGaleryState.isOpen} fsGaleryActn={fsGaleryActn}>
                <ImageWithControlsWrapper
                    imageData={lesionRecordState.images[fsGaleryState.currentIndex]}
                    predictionPending={!!lesionRecordState.pendingPredictions.find(imageId => imageId === lesionRecordState.images[fsGaleryState.currentIndex].id)}
                    onDelete={onRemoveImage}
                    onUpdateImageType={onUpdateImageType}
                    imageContent={lesionRecordState.images[fsGaleryState.currentIndex] && lesionRecordState.images[fsGaleryState.currentIndex].content}
                />
            </NewFullscreenGalery>

        </ScreenContent>
    );
};

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default LesionRecordEditor;
