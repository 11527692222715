import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';

import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { useRedirect } from 'common/common.hooks';
import { VisitContext } from 'modules/Visit/Visit.context';
import ImageRepo from '~legacy/ImageRepo';

import Button from 'common/Button/Button';
import RadioButton from 'common/RadioButton/RadioButton';
import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';

import LesionRecordItem from 'modules/Visit/VisitDetails/MedicalRecords/LesionRecordItem';
import Documents from 'modules/Visit/VisitDetails/Documents/Documents';
import styles from './VisitDetails.module.css';

import hu from 'date-fns/locale/hu';
registerLocale('hu', hu)

const VisitDetails = ({ match }) => {
    const { patientId, visitId, recordId } = match.params;

    const {
        visitState,
        visitActn,
        patientState,
        patientActn,
        usersState,
        usersActn,
    } = useContext(VisitContext);

    const [redirectState, redirectActn] = useRedirect();

    // -------------------------------------------------------------------------------------------------------------------------------------- helpers --

    const createMDRecordsPostData = ({ recordId, isNew = false, indexHax }) => {
        const { medicalRecords, medicalRecordImages } = visitState;

        const mdRecordData = medicalRecords.byId[recordId];
        const newImageFiles = mdRecordData.newDocuments.map(id => medicalRecordImages.byId[id].file);

        // TODO: refactor
        const { documentsNewPredictions, docTypes } = mdRecordData.documents.reduce((accumulator, currentId) => {
            const mdRecordImage = medicalRecordImages.byId[currentId];

            const docTypes = {
                ...accumulator.docTypes,
                [`documentId-${currentId}`]: mdRecordImage.type,
            };

            const documentsNewPredictions = mdRecordImage.predictionResult.id ? accumulator.documentsNewPredictions : {
                ...accumulator.documentsNewPredictions,
                [`documentId-${currentId}`]: mdRecordImage.predictionResult,
            };

            return { documentsNewPredictions, docTypes };
        }, { documentsNewPredictions: {}, docTypes: {} });

        // TODO: refactor
        const { newDocumentsPredictions, newDocTypes } = mdRecordData.newDocuments.reduce((accumulator, currentId, index) => {
            const mdRecordImage = medicalRecordImages.byId[currentId];

            const newDocTypes = {
                ...accumulator.newDocTypes,
                [`imageIndex-${indexHax.currentImageIndex}`]: mdRecordImage.type,
            };

            const newDocumentsPredictions = {
                ...accumulator.newDocumentsPredictions,
                [`imageIndex-${indexHax.currentImageIndex}`]: mdRecordImage.predictionResult,
            };

            indexHax.currentImageIndex += 1;

            return { newDocumentsPredictions, newDocTypes };
        }, { newDocumentsPredictions: {}, newDocTypes: {} });

        console.log('>>>> new image files', newImageFiles, 'for medical record', mdRecordData);

        const mdRecord = {
            suspicion: mdRecordData.suspicion,
            diagnosis: mdRecordData.diagnosis,
            images: [...newImageFiles],
            documentIds: [...mdRecordData.documents],
            contentIds: [],
            predictionResults: {...documentsNewPredictions, ...newDocumentsPredictions},
            docTypes: { ...docTypes, ...newDocTypes },
        };

        // add 'medical record id' if it is an existing record
        return isNew ? mdRecord : { ...mdRecord, id: mdRecordData.id };
    }

    // ----------------------------------------------------------------------------------------------------------------------------------- UI actions --

    const onSave = () => {
        const { visit, documents } = visitState;
        const { patient } = patientState;

        // TODO: refactor later... this server API is so much fun!
        const indexHax = { currentImageIndex: 0 };

        const newDocumentFiles = visit.newDocuments.map(id => documents.byId[id].file);
        const existingMedicalRecods = visit.medicalRecords.map(id => createMDRecordsPostData({ recordId: id, indexHax }));
        const newMedicalRecords = visit.newMedicalRecords.map(id => createMDRecordsPostData({ recordId: id, isNew: true, indexHax }));

        const visitData = {
            patientId: patient.id,
            patientAgreement: visit.permission,
            uptake: new Date(visit.date),
            mdId: visit.mdId,

            documentContents: newDocumentFiles,
            documentIds: [...visit.documents],
            documentContentIds: [],
            medicalRecords: [...existingMedicalRecods, ...newMedicalRecords],
        };

        const visitPostData = visitId ? { ...visitData, id: visitId } : visitData;

        console.log('[save visit] ----------------- visitState:', visitState);
        console.log('[save visit] ----------------- visitData prepared for service:', visitPostData);

        if (visitData.medicalRecords.length === 0) {
            window.alert('legalább egy bőrelváltozás feltöltése kötelező!');
        } else {
            visitActn.saveVisit({ visit: visitPostData });
        }
    };

    const onCancel = () => redirectActn.setLocation(`/patients/${patientId}`);

    const onDelete = () => {
        const isConfirmed = window.confirm(i18next.t('Please confirm delete operation'));
        if (isConfirmed) visitActn.deleteVisit({ id: visitId });
    };

    const onEditDocuments = () => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}/documents`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create/documents`);

    const onNewDocument = () => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}/documents`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create/documents`);

    const onEditLesionRecord = (id) => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}/records/${id}`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create/records/${id}`);

    const onDownloadImages = (id) => ImageRepo.downloadAllByRecordId(id);

    const onNewLesionRecord = () => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}/records/create`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create/records/create`);

    const onTherapistChange = (e) => {
        if (e) {
            const newTherapistId = e.value;
            visitActn.updateTherapist({ id: newTherapistId });
        }
    }

    const onNewTherapist = (e) => {
        if (e) {
            visitActn.updateTherapist({ id: e });
            usersActn.addUser(e);
        }
    }

    const onPermissionChange = (e) => {
        const newPermission = Number(e.target.value);
        visitActn.updatePermission({ permission: newPermission });
    }

    const onDateChange = (dateString) => {
        try {
            const newDate = new Date(dateString).toISOString();
            visitActn.updateDate({ date: newDate });
        } catch {
            console.log('invalid date');
        }
    }

    // -------------------------------------------------------------------------------------------------------------------------------------- effects --

    useEffect(() => {
        if (patientId && patientState.patient.id !== Number(patientId)) patientActn.getPatientById(patientId);
        if (visitId && visitState.visit.id !== Number(visitId)) visitActn.getVisitById(visitId);
        if (usersState.users.length === 0) usersActn.getUsers();
    }, []);

    useEffect(() => {
        const shouldRedirect = visitState.saveVisitSuccess || visitState.deleteVisitSuccess;

        if (shouldRedirect) redirectActn.setLocation(`/patients/${patientId}`);
    }, [visitState])

    // ------------------------------------------------------------------------------------------------------------------------ prepare for rendering --

    const documents = visitState.visit.documents.map(documentId => visitState.documents.byId[documentId]);
    const newDocuments = visitState.visit.newDocuments.map(documentId => visitState.documents.byId[documentId]);
    const mergedDocuments = [ ...documents, ...newDocuments ];

    const medicalRecords = visitState.visit.medicalRecords.map(mdRecordId => visitState.medicalRecords.byId[mdRecordId]);
    const newMedicalRecords = visitState.visit.newMedicalRecords.map(mdRecordId => visitState.medicalRecords.byId[mdRecordId]);
    const mergedMedicalRecords = [ ...medicalRecords, ...newMedicalRecords ];

    // 'en-CA' conversion is required because the HTML5 date input field requires the format: yyyy-mm-dd
    const currentDate = new Date(visitState.visit.date).toLocaleDateString('en-CA');
    const dateFormat = navigator.language === "hu" ? "yyyy/MM/dd" : "dd/MM/yyyy"

    const mdOptions = usersState.users.map(user =>
        ({
            value: user.id,
            label: user.name
        })
    ).concat(usersState.newTherapistName ? {
        value: usersState.newTherapistName,
        label: usersState.newTherapistName
    } : []);

    // ------------------------------------------------------------------------------------------------------------------------------------ rendering --

    return redirectState.location
        ? <Redirect to={redirectState.location} push />
        : patientState.getPatientPending || visitState.getVisitPending || usersState.getUsersPending
        ? <LoadingIndicator />
        : (
        <ScreenContent>
            <ScreenTitle>
                { visitId ? <Trans>Visit details</Trans> : <Trans>New visit</Trans> }
            </ScreenTitle>

            <ScreenControls className={styles.controlsContainer}>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation('/patients')}>
                        <Trans>Patients</Trans>
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(`/patients/${patientId}`)}>
                        { patientState.patient.name }
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    { visitId ?
                    <Button onClick={e => onDelete()}>
                        <Trans>Delete</Trans>
                    </Button> : null }
                    <Button className={styles.marginR20} onClick={e => onCancel()}>
                        <Trans>Discard</Trans>
                    </Button>
                    <Button primary onClick={e => onSave()}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </ScreenControls>

            <div className={styles.splitContainer}>
                <div className={styles.splitContainerItem}>
                    <label className={styles.label}>
                        <Trans>Doctor</Trans>
                    </label>
                    <CreatableSelect
                        isClearable
                        defaultValue={mdOptions.find((e) => e.value === visitState.visit.mdId) || undefined}
                        onChange={onTherapistChange}
                        onInputChange={onNewTherapist}
                        options={mdOptions}
                    />
                </div>
                <div className={styles.splitContainerItem}>
                    <label className={styles.label}>
                        <Trans>Photo date</Trans>
                    </label>
                    <DatePicker
                        className={styles.datepicker}
                        selected={new Date(visitState.visit.date)}
                        dateFormat={dateFormat}
                        onChange={onDateChange}
                        locale={navigator.language}
                    />
                </div>
            </div>

            <div className={styles.splitContainer}>
                <div className={styles.splitContainerItem}>
                    <label className={styles.label}>
                        <Trans>Upload pictures</Trans>
                    </label>
                    <div className={styles.uploadButtonsContainer}>
                        <button className={styles.uploadButton} onClick={onNewLesionRecord}>
                            <img src="/images/dermatoscope150.png" alt="dermatoscope"/>
                            <span>
                                <Trans>New skin lesion</Trans>
                            </span>
                        </button>
                        <button className={styles.uploadButton} onClick={onNewDocument}>
                            <img src="/images/doc150.png" alt="documents"/>
                            <span>
                                <Trans>New documents</Trans>
                            </span>
                        </button>
                    </div>
                </div>

                <div className={styles.splitContainerItem}>
                    <label className={styles.label}>
                        <Trans>Permissions</Trans>
                    </label>
                    <form>
                        <RadioButton
                            onChange={onPermissionChange}
                            id="0"
                            value="0"
                            checked={visitState.visit.permission === 0}
                            text={i18next.t('Permission granted to use the images')} />
                        <RadioButton
                            onChange={onPermissionChange}
                            id="1"
                            value="1"
                            checked={visitState.visit.permission === 1}
                            text={i18next.t('Face cannot be used')} />
                        <RadioButton
                            onChange={onPermissionChange}
                            id="2"
                            value="2"
                            checked={visitState.visit.permission === 2}
                            text={i18next.t('Permission denied to use the images')} />
                    </form>
                </div>
            </div>

            { mergedDocuments.length !== 0 ?
                <Documents documentsData={mergedDocuments} onEdit={onEditDocuments} /> : null
            }

            { mergedMedicalRecords.map(medicalRecord => {
                const images = medicalRecord.documents.map(imageId => visitState.medicalRecordImages.byId[imageId]);
                const newImages = medicalRecord.newDocuments.map(imageId => visitState.medicalRecordImages.byId[imageId]);
                const mergedImages = [...images, ...newImages];

                return (
                    <LesionRecordItem
                        key={medicalRecord.id}
                        recordData={medicalRecord}
                        images={mergedImages}
                        onEdit={() => onEditLesionRecord(medicalRecord.id)}
                        onDownload={() => onDownloadImages(medicalRecord.id)}
                        isNewRecord={!!newMedicalRecords.find(currendRecord => currendRecord.id === medicalRecord.id)}
                    />);
            })}

        </ScreenContent>
        );
};

export default VisitDetails;
