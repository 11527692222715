import React from 'react';
import Svg from 'common/Svg/Svg';
import styles from './ItemList.module.css';


const ItemList = ({ addItemAction, addItemText, children, className }) => {
    const containerClasses = [className, styles.container].join(' ');

    return (
        <div className={containerClasses}>
            { addItemAction && addItemText ?
            <button className={styles.addItemButton} onClick={addItemAction}>
                <Svg className={styles.svg} name="plus" size="30"/>
                <span>{ addItemText }</span>
            </button> : null }
            <div className={styles.scrollContainer}>
                { children }
            </div>
        </div>
    );
}

export default ItemList;
