import axios from 'axios';
import auth0Client from '../Auth';

const patientsUrl = '/webapi/patients/';

class PatientRepo {
	constructor() {
		this.listByName = this.listByName.bind(this);
		this.list = this.list.bind(this);
		this.getById = this.getById.bind(this);
		this.createPatient = this.createPatient.bind(this);
		this._getFromApi = this._getFromApi.bind(this);
	}
	
	listByName(value, callBack, errorCallback) {	
	  const url = patientsUrl + ((value === '') ? '' : 'name/' + value + '/');
		this._getFromApi(url, callBack, errorCallback);
	}

	list(callBack, errorCallback) {	
	  this._getFromApi(patientsUrl, callBack, errorCallback);
	}
	
	getById(id, callBack) {	
		const url = patientsUrl + 'id/' + id + '/';
		this._getFromApi(url, callBack, null);
	}

	_getFromApi(url, callBack, errorCallback){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
	  axios.get(url,{ headers: { Authorization: authStr } }).then(
		  (response) => {				
				callBack(response.data);
		  },	
			(error) => { 
				console.log(error);
				if (errorCallback) {
					errorCallback(error);
				}
			}		
	  )
	}

	createPatient(patient, callback, errorCallback){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		axios.post(patientsUrl, patient, { headers: { Authorization: authStr } }).then((response) => {
				callback(response.data);
		  },	
			(error) => { 				
				if (errorCallback) {
					errorCallback(error);
				}
			}		
		);
	}

	updatePatient(patient, callback, errorCallback){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
		return axios.put(patientsUrl + 'id/' + patient.id + '/', patient, { headers: { Authorization: authStr } }).then((response) => {
				callback(response.data);
		  },	
			(error) => { 				
				if (errorCallback) {
					errorCallback(error);
				}
			}		
		);
	}

}

const patientRepo = new PatientRepo();

export default patientRepo;