import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { useRedirect } from 'common/common.hooks';
import { usePatient } from 'modules/Patients/Patients.hooks';
import { useRecords } from 'modules/Records/Records.hooks';

import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';
import Button from 'common/Button/Button';
import SearchField from 'common/SearchField/SearchField';
import ItemList from 'common/ItemList/ItemList';
import { RecordListHeader, RecordListItem } from 'modules/Records/RecordList/RecordList';

import styles from './Patient.module.css';

const Patient = ({ match, location, history }) => {
    const { patientId } = match.params;

    const [redirectState, redirectActn] = useRedirect();
    const [patientState, patientActn] = usePatient();
    const [recordsState, recordsActn] = useRecords();

    const onEditPatient = () => redirectActn.setLocation(`/patients/${patientId}/details`);
    const onNewVisit = () => redirectActn.setLocation(`/patients/${patientId}/visits/create`);

    const onSearchRecords = (searchString) => {
        const safeString = searchString.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        recordsActn.getRecords({ patientId, searchText: safeString });
    };

    useEffect(() => {
        patientActn.getPatientById(patientId);
        recordsActn.getRecords({ patientId });
    }, []);

    return redirectState.location
        ? <Redirect to={redirectState.location} push />
        : patientState.getPatientPending
        ? <LoadingIndicator />
        : (
        <ScreenContent>
            <ScreenTitle>
                {patientState.patient.name}
            </ScreenTitle>

            <ScreenControls className={styles.controlsContainer}>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation('/patients')}>
                        <Trans>Patients</Trans>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    <Button onClick={onEditPatient} icon="pencil" wide><Trans>Edit patient</Trans></Button>
                </div>
            </ScreenControls>

            <SearchField
                className={styles.searchField}
                icon="search"
                placeholder={i18next.t('Search medical record')}
                onChange={searchString => onSearchRecords(searchString)}
            />
            <div className={styles.recordList}>
                <RecordListHeader className={styles.recordListHeader} patientView />
                <ItemList addItemText={i18next.t('Add new visit')} addItemAction={onNewVisit}>
                    { recordsState.records.map(record => <RecordListItem key={record.id} record={record} patientView />) }
                </ItemList>
            </div>
        </ScreenContent>
        );
};

Patient.propTypes = {
    match: PropTypes.object,
};

export default withRouter(Patient);
