import React, { useEffect, useState } from 'react';
import Svg from 'common/Svg/Svg';
import styles from './FullscreenGalery.module.css';

// --------------------------------------------------------------------------------------------------------------------------- fullscreen galery hook --

const useFullscreenGalery = () => {
    const [state, setState] = useState({
        isOpen: false,
        images: [],
        currentIndex: 0,
    });

    const openGalery = ({ currentIndex, images }) => setState(prevState => ({
        ...prevState,
        isOpen: true,
        currentIndex,
        images,
    }))

    const closeGalery = () => setState(prevState => ({
        ...prevState,
        isOpen: false,
        currentIndex: 0,
        images: [],
    }));

    const nextImage = () => setState(prevState => {
        const { images, currentIndex } = prevState;
        const nextImage = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

        return { ...prevState, currentIndex: nextImage };
    });

    const prevImage = () => setState(prevState => {
        const { images, currentIndex } = prevState;
        const nextImage = currentIndex === 0 ? images.length - 1 : currentIndex - 1;

        return { ...prevState, currentIndex: nextImage };
    })

    return [state, {
        openGalery,
        closeGalery,
        nextImage,
        prevImage,
    }];
};

// ------------------------------------------------------------------------------------------------------------------ NEW fullscreen galery component --

const NewFullscreenGalery = ({ children, isOpen, fsGaleryActn }) => {

    useEffect(() => {
        function fsGaleryEventHandler (e) {
            if (e.keyCode === 39) {
                fsGaleryActn.nextImage();
            } else if (e.keyCode === 37) {
                fsGaleryActn.prevImage();
            } else if (e.keyCode === 27) {
                fsGaleryActn.closeGalery();
            }
        }

        window.removeEventListener('keydown', fsGaleryEventHandler);
        window.addEventListener('keydown', fsGaleryEventHandler);

        return (() => window.removeEventListener('keydown', fsGaleryEventHandler));
    }, []);

    const onNextWrapper = (e) => {
        e.stopPropagation();
        fsGaleryActn.nextImage();
    };

    const onPrevWrapper = (e) => {
        e.stopPropagation();
        fsGaleryActn.prevImage();
    };

    const onCloseWrapper = (e) => {
        e.stopPropagation();
        fsGaleryActn.closeGalery();
    }

    return !isOpen ? null : (
        <div className={styles.container} onClick={onCloseWrapper}>
            <button className={styles.closeButton} onClick={onCloseWrapper}>
                <Svg name="icon-close" size="50"/>
            </button>
            <button className={styles.prevButton} onClick={onPrevWrapper}>
                <Svg name="caret" size="50"/>
            </button>

            {children}

            <button className={styles.nextButton} onClick={onNextWrapper}>
                <Svg name="caret" size="50"/>
            </button>
        </div>
    );
};

// ---------------------------------------------------------------------------------------------------------------------- fullscreen galery component --

// TODO: rewrite other components to use the new fullscreen galery
const FullscreenGalery = ({ image, onNext, onPrev, onClose }) => {

    useEffect(() => {
        function fsGaleryEventHandler (e) {
            if (e.keyCode === 39) {
                onNext();
            } else if (e.keyCode === 37) {
                onPrev();
            } else if (e.keyCode === 27) {
                onClose();
            }
        }

        window.removeEventListener('keydown', fsGaleryEventHandler);
        window.addEventListener('keydown', fsGaleryEventHandler);

        return (() => window.removeEventListener('keydown', fsGaleryEventHandler));
    }, [onClose, onNext, onPrev]);

    const onNextWrapper = (e) => {
        e.stopPropagation();
        onNext();
    };

    const onPrevWrapper = (e) => {
        e.stopPropagation();
        onPrev();
    };

    const onCloseWrapper = (e) => {
        e.stopPropagation();
        onClose();
    }

    const imageSource = image.content.data.slice(0,4) === 'blob'
        ? image.content.data
        : `data:image;base64,${image.content.data}`;

    return (
        <div className={styles.container} onClick={onCloseWrapper}>
            <button className={styles.closeButton} onClick={onCloseWrapper}>
                <Svg name="icon-close" size="50"/>
            </button>
            <button className={styles.prevButton} onClick={onPrevWrapper}>
                <Svg name="caret" size="50"/>
            </button>
            <div>
                <img
                    onClick={e => e.stopPropagation()}
                    className={styles.image}
                    src={imageSource}
                    alt="temp"
                />
            </div>
            <button className={styles.nextButton} onClick={onNextWrapper}>
                <Svg name="caret" size="50"/>
            </button>
        </div>
    );
};

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default FullscreenGalery;
export { useFullscreenGalery, NewFullscreenGalery };
