import React from 'react';
import withControlsOverlay from './Overlays/withControlsOverlay';
import withDetailsOverlay from './Overlays/withDetailsOverlay';
import withImageDetails from './Wrappers/withImageDetails';
import withImageControls from './Wrappers/withImageControls';
import styles from './ImageItem.module.css';

// ------------------------------------------------------------------------------------------------------------------------------ ImageItem component --

const ImageItem = ({ className, imageContent }) => {
    const imageClassList = className ? [styles.image, className].join(' ') : styles.image;

    const imageSource = imageContent.data.slice(0,4) === 'blob'
        ? imageContent.data
        : `data:image;base64,${imageContent.data}`;

    return (
        <img
            className={imageClassList}
            src={imageSource}
            alt="temp"
        />
    );
};

// ----------------------------------------------------------------------------------------------------------------------------- decorated components --

// TODO: refactor later from HOC to components
const ImageWithControlsOverlay = withControlsOverlay(ImageItem);
const ImageWithDetailsOverlay = withDetailsOverlay(ImageItem);
const ImageWithDetailsWrapper = withImageDetails(ImageItem);
const ImageWithControlsWrapper = withImageControls(ImageItem);

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default ImageItem;
export { ImageWithDetailsOverlay, ImageWithControlsOverlay, ImageWithDetailsWrapper, ImageWithControlsWrapper };
