import React from 'react';
import styles from './ScreenTitle.module.css';

const ScreenTitle = ({ children }) => (
    <h1 className={styles.title}>
        { children }
    </h1>
);

export default ScreenTitle;
