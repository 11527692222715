import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import predictionSrvc from 'common/prediction.srvc';

import Button from 'common/Button/Button';
import styles from './withImageControls.module.css';

const withImageControls = WrappedComponent => (props) => {
    const {
        onUpdateImageType, onDelete, onClick, predictionPending, imageData, className, ...wrappedComponentProps
    } = props;

    const onDeleteWrapper = (e) => {
        e.stopPropagation();
        onDelete({ image: imageData });
    };

    const onSetMicro = (e) => {
        e.stopPropagation();
        onUpdateImageType({ imageId: imageData.id, imageType: 'skinMicroPic' });
    };

    const onSetMacro = (e) => {
        e.stopPropagation();
        onUpdateImageType({ imageId: imageData.id, imageType: 'skinMacroPic' })
    };

    const progressOverlayClass = predictionPending ? styles.overlayProgress : styles.hidden;
    const microClass = imageData.type === 'skinMicroPic' ? styles.label : styles.labelInActive;
    const macroClass = imageData.type === 'skinMacroPic' ? styles.label : styles.labelInActive;

    const predictions = imageData.predictionResult ? imageData.predictionResult.allPredictions : {};
    const sortedPredictions = predictionSrvc.sortPredictions(predictions);

    const primaryPrediction = sortedPredictions.pop();
    const secondaryPrediction = sortedPredictions.pop();

    const primaryPredictionText = primaryPrediction ?
        `${i18next.t("bnoShort:" + primaryPrediction.type, primaryPrediction.type)} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
        `${i18next.t("bnoShort:" + secondaryPrediction.type, secondaryPrediction.type)} ${Math.round(secondaryPrediction.percent * 100)}%`: null;

    return (
        <div className={styles.container}>

            <div className={styles.topBar}>
                <span className={styles.labelFat}>
                    { primaryPredictionText }
                </span>
                <span className={styles.labelFat}>
                    { secondaryPredictionText }
                </span>
                <Button className={styles.deleteButton} onClick={onDeleteWrapper} icon="icon-trashcan">
                    <Trans>Delete</Trans>
                </Button>
            </div>

            <div>
                <div className={progressOverlayClass}>
                    <p>
                        <Trans>In progress</Trans>
                    </p>
                </div>
                <WrappedComponent className={styles.wrappedImage} {...wrappedComponentProps} />
            </div>

            <div className={styles.bottomBar}>
                <span className={microClass} onClick={onSetMicro}>
                    micro
                </span>
                <span className={macroClass} onClick={onSetMacro}>
                    macro
                </span>
            </div>

        </div>
    );
}

export default withImageControls;
