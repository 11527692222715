import React, { useEffect } from 'react';
import i18next from 'i18next';

import ScreenContent from 'common/ScreenContent/ScreenContent';
import SearchField from 'common/SearchField/SearchField';
import ItemList from 'common/ItemList/ItemList';
import { RecordListHeader, RecordListItem } from 'modules/Records/RecordList/RecordList';
import { useRecords } from './Records.hooks';

import styles from './Records.module.css';

const Records = () => {
    const [recordsState, recordsActn] = useRecords();

    const onSearchRecords = (searchString) => {
        const safeString = searchString.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        recordsActn.getRecords({ searchText: safeString });
    };

    useEffect(() => {
        recordsActn.getRecords();
    }, []);

    return (
        <ScreenContent>
            <SearchField
                className={styles.searchField}
                icon="search"
                placeholder={i18next.t('Search medical record')}
                onChange={searchString => onSearchRecords(searchString)}
            />
            <div className={styles.recordList}>
                <RecordListHeader className={styles.recordListHeader} />
                <ItemList>
                    { recordsState.records.map(record => <RecordListItem key={record.id} record={record} />) }
                </ItemList>
            </div>
        </ScreenContent>
    );
};

export default Records;
