import axios from 'axios';
import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

const quizUrl = '/webapi/quiz/';

class QuizRepo {
	constructor() {
		this.getQuestions = this.getQuestions.bind(this);
		this._getFromApi = this._getFromApi.bind(this);
	}

	getQuestions(callBack, errorCallback) {
	  const url = quizUrl + "questions";
	  const params = {limit: 15, nrOfPossibleAnswers: 8};
	  this._getFromApi(url, params, callBack, errorCallback);
	}

	_getFromApi(url, params, callBack, errorCallback){
	  axios.get(url,{ params: params }).then(
		  (response) => {
			const quiz =  {
				"quizTitle": <Trans>Skin Lesion Quiz</Trans>,
				"quizSynopsis": <Trans>Try to categorize the upcoming pictures</Trans>,
				"questions": [],
				"appLocale": i18n.getResourceBundle(i18n.language, "quiz")
			};
			let aiPoints = 0;
			response.data.forEach(questionResponse => {
				let question = {
					question: i18n.t("What is on the picture?"),
					questionType: "text",
					answerSelectionType: "single",
					questionPic: "data:image;base64," + questionResponse.pictureToGuess,
					answerCodes: questionResponse.possibleAnswers,
					answers: [],
					correctAnswer: new String(questionResponse.correctAnswerIndex + 1),
					messageForCorrectAnswer: i18n.t("Correct categorization!"),
					messageForIncorrectAnswer: i18n.t("Incorrect categorization."),
					explanation: "",
					point: 1};
				if (questionResponse.aiAnswer == questionResponse.correctAnswer){
					aiPoints++;
				}
				question.answerCodes.forEach(answerCode => {
					question.answers.push({content: i18n.t("bnoLong:" + answerCode, answerCode), 
						otherParticipants: answerCode == questionResponse.aiAnswer ? 'EMDEE.AI' : null});
				});
				console.log({possibleAnswers: questionResponse.possibleAnswers, correctAnswer: questionResponse.correctAnswer, aiAnswer: questionResponse.aiAnswer});
				quiz.questions.push(question);
			});
			quiz.aiResult = i18n.t("EMDEE.AI score") + ": " + aiPoints;
			callBack(quiz);
		  },
		  (error) => {
				console.log(error);
				if (errorCallback) {
					errorCallback(error);
				}
		  }
	  )
	}

}

const quizRepo = new QuizRepo();

export default quizRepo;