import i18n from "i18next";
import backend from "i18next-xhr-backend";
import { reactI18nextModule } from "react-i18next";

import translationEN from './locales/en/translation.json';
import bnoShortEN from './locales/en/bnoShort.json';
import bnoLongEN from './locales/en/bnoLong.json';
import quizEN from './locales/en/quiz.json';
import translationHU from './locales/hu/translation.json';
import bnoShortHU from './locales/hu/bnoShort.json';
import bnoLongHU from './locales/hu/bnoLong.json';
import quizHU from './locales/hu/quiz.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
    bnoShort: bnoShortEN,
    bnoLong: bnoLongEN,
    quiz: quizEN
  },
  hu: {
    translation: translationHU,
    bnoShort: bnoShortHU,
    bnoLong: bnoLongHU,
    quiz: quizHU
  }
};

// i18next-browser-languageDetector

i18n
  .use(backend)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

	resources: resources,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
export default i18n;