import React from 'react';
import Dropzone from 'react-dropzone';
import { Trans } from 'react-i18next';

import Svg from 'common/Svg/Svg';
import styles from './ImageDropzone.module.css';

const ImageDropzone = ({ children, onAddImages }) => {

    const onDropHandler = (acceptedFiles) => onAddImages({ imageFiles: acceptedFiles });

    return (
        <Dropzone onDrop={onDropHandler} accept="image/jpeg" >
        {({getRootProps, getInputProps}) => (
            <section className={styles.container}>
                <div {...getRootProps({
                    onClick: e => console.log('dropzone click', e),
                })}>
                    <input {...getInputProps()} />
                    <p className={styles.dzText}>
                        <Svg name="plus" size="30"/>
                        <span>
                            <Trans>Drop images here or click to upload</Trans>
                        </span>
                    </p>
                    <div className={styles.imagesContainer}>
                        { children }
                    </div>
                </div>
            </section>
        )}
        </Dropzone>
    );
}

export default ImageDropzone;
