import React from 'react';
import { Trans } from 'react-i18next';
import ImageGalery from 'common/ImageGalery/ImageGalery';
import Button from 'common/Button/Button';
import { ImageWithDetailsWrapper } from 'modules/Visit/~common/ImageItem/ImageItem';
import { ImageWithDetailsOverlay } from 'modules/Visit/~common/ImageItem/ImageItem';
import { useFullscreenGalery, NewFullscreenGalery } from 'common/ImageGalery/FullscreenGalery';
import styles from './LesionRecordItem.module.css';

const LesionRecordItem = ({ recordData, images, isNewRecord, onEdit, onDownload }) => {
    const [ fsGaleryState, fsGaleryActn ] = useFullscreenGalery();

    const onImageClicked = ({ event, index }) => {
        event.stopPropagation();
        fsGaleryActn.openGalery({ currentIndex: index, images });
    };

    const label = recordData.diagnosis
        ? <Trans>Histology</Trans>
        : recordData.suspicion
        ? <Trans>Supposed diagnosis</Trans>
        : null;

    const text = recordData.diagnosis || recordData.suspicion || null;

    return (
        <div className={styles.container}>
            <div className={styles.titlebarContainer}>
                <span className={styles.textTitle}><Trans>Skin lesion</Trans></span>
                <div className={styles.buttonsContainer}>
                    { !isNewRecord ?
                        <Button onClick={onDownload}><Trans>Download images</Trans></Button> : null }
                    <Button onClick={onEdit} icon="pencil"><Trans>Edit</Trans></Button>
                </div>
            </div>

            <div className={styles.recordDetails}>
                <p>
                    <span className={styles.textLabel}>{label}</span>
                    <span>{text}</span>
                </p>
            </div>

            {/* TODO: consider creating an image galery wrapper component like LesionRecordImageGalery */}
            <div className={styles.imagesContainer}>
            { images.map((imageItem, index) => (
                <ImageWithDetailsOverlay
                    className={styles.imageContainer}
                    key={imageItem.id}
                    imageData={imageItem}
                    onClick={(event) => onImageClicked({ event, index })}
                    imageContent={imageItem.content}
                />
            ))}
            </div>

            <NewFullscreenGalery isOpen={fsGaleryState.isOpen} fsGaleryActn={fsGaleryActn}>
                <ImageWithDetailsWrapper
                    imageData={images[fsGaleryState.currentIndex]}
                    imageContent={images[fsGaleryState.currentIndex] && images[fsGaleryState.currentIndex].content}
                />
            </NewFullscreenGalery>

        </div>
    );
};

export default LesionRecordItem;
