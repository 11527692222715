import React from 'react';
import Button from 'common/Button/Button'
import styles from './Breadcrumb.module.css';

// ------------------------------------------------------------------------------------------------------------------------- BreadcrumbItem component --

const BreadcrumbItem = ({ children, onClick }) => (
    <Button className={styles.breadcrumbItem} onClick={onClick} icon="caret">
        { children }
    </Button>
);

// ----------------------------------------------------------------------------------------------------------------------------- Breadcrumb component --

const Breadcrumb = ({ children }) => (
    <div className={styles.breadcrumbContainer}>
        { children }
    </div>
);

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export {
    Breadcrumb,
    BreadcrumbItem,
};
