import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PatientListItem.module.css';

// type Patient = {
//     id: Number,
//     name: String,
//     description: String,
//     googleId?: String,
//     apiToken?: String,
//     emailAddress?: String,
// };

// type PatientListItemProps = {
//     patient: Patient,
// };

const PatientListItem = ({ patient }) => (
    <Link className={styles.link} to={`/patients/${patient.id}`}>
        <div className={styles.container}>
            <p className={styles.nameText}>{patient.name}</p>
            <p className={styles.descriptionText}>{patient.description}</p>
        </div>
    </Link>
);

export default PatientListItem;
