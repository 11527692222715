import React from 'react';

const icons = {
  'search': 'M18.8907479,17.4765344 L21.6568542,20.2426407 C22.0473785,20.633165 22.0473785,21.26633 21.6568542,21.6568542 C21.26633,22.0473785 20.633165,22.0473785 20.2426407,21.6568542 L17.4765344,18.8907479 C16.4957155,19.589209 15.2958304,20 14,20 C10.6862915,20 8,17.3137085 8,14 C8,10.6862915 10.6862915,8 14,8 C17.3137085,8 20,10.6862915 20,14 C20,15.2958304 19.589209,16.4957155 18.8907479,17.4765344 Z M14,17.6 C15.9882251,17.6 17.6,15.9882251 17.6,14 C17.6,12.0117749 15.9882251,10.4 14,10.4 C12.0117749,10.4 10.4,12.0117749 10.4,14 C10.4,15.9882251 12.0117749,17.6 14,17.6 Z',
  'caret': 'M12.4142136,13 L17.5857864,13 C18.1380712,13 18.5857864,13.4477153 18.5857864,14 C18.5857864,14.2652165 18.4804296,14.5195704 18.2928932,14.7071068 L15,18 L11.7071068,14.7071068 C11.3165825,14.3165825 11.3165825,13.6834175 11.7071068,13.2928932 C11.8946432,13.1053568 12.1489971,13 12.4142136,13 Z',
}

function Icon(props) {

  return(
    <svg
      className="icon"
      width={props.size}
      height={props.size}
      viewBox="0 0 30 30">
      <path d={icons[props.icon]}></path>
    </svg>
  );
}

export default Icon;
