import predictionRepo from '~legacy/PredictionRepo';

// const mockMicroPrediction = () => JSON.stringify({
//     "id": null,
//     "type": "melanomaDetectionMicro",
//     "creationTime": new Date().toISOString(),
//     "result": "NV",
//     "probability": Math.floor(Math.random().toString() * 100),
//     "allPredictions": {
//         "AKIEC": "6.535064933643753e-09",
//         "BCC": "2.5864067083603004e-06",
//         "BKL": "4.896820655631018e-07",
//         "DF": "5.79633951947045e-10",
//         "MEL": "0.25004988905857317",
//         "NV": "0.749648779630661",
//         "VASC": "0.000298219732940197"
//     }
// });

// const mockMacroPrediction = () => JSON.stringify({
//     "id": null,
//     "type": "melanomaDetectionMacro",
//     "creationTime": new Date().toISOString(),
//     "result": "NV",
//     "probability": Math.floor(Math.random().toString() * 100),
//     "allPredictions": {
//         "AKIEC": "6.535064933643753e-09",
//         "BCC": "2.5864067083603004e-06",
//         "BKL": "4.896820655631018e-07",
//         "DF": "5.79633951947045e-10",
//         "MEL": "0.25004988905857317",
//         "NV": "0.749648779630661",
//         "VASC": "0.000298219732940197"
//     }
// });

const mockMicroPrediction = () => JSON.stringify({
    "id": null,
    "type": "melanomaDetectionMicro",
    "creationTime": new Date().toISOString(),
    "result": "NV",
    "probability": Math.floor(Math.random() * 100),
    "allPredictions": {
        "AKIEC": Math.random().toString(),
        "BCC": Math.random().toString(),
        "BKL": Math.random().toString(),
        "DF": Math.random().toString(),
        "MEL": Math.random().toString(),
        "NV": Math.random().toString(),
        "VASC": Math.random().toString()
    }
});

const mockMacroPrediction = () => JSON.stringify({
    "id": null,
    "type": "melanomaDetectionMacro",
    "creationTime": new Date().toISOString(),
    "result": "NV",
    "probability": Math.floor(Math.random() * 100),
    "allPredictions": {
        "AKIEC": Math.random().toString(),
        "BCC": Math.random().toString(),
        "BKL": Math.random().toString(),
        "DF": Math.random().toString(),
        "MEL": Math.random().toString(),
        "NV": Math.random().toString(),
        "VASC": Math.random().toString()
    }
});

const predictMicroImage = ({ image, index = 0, groupIndex = 0 }) => new Promise((resolve, reject) => {
    const onSuccess = result => {
        console.log('>>>>>>>>>> pred result', result);
        resolve(result);
    }

    console.log('running prediciton on', image);

    predictionRepo.predictByMicroImage(image, index, groupIndex, onSuccess);

    // const temp = JSON.parse(mockMicroPrediction());

    // setTimeout(() => {
    //     onSuccess(temp);
    // }, 1500);
});

const predictMacroImage = ({ image, index = 0, groupIndex = 0 }) => new Promise((resolve, reject) => {
    const onSuccess = result => {
        console.log('>>>>>>>>>> pred result', result);
        resolve(result);
    }

    console.log('running prediciton on', image);

    predictionRepo.predictByMacroImage(image, index, groupIndex, onSuccess);

    // const temp = JSON.parse(mockMacroPrediction());

    // setTimeout(() => {
    //     onSuccess(temp);
    // }, 1500);
});

const sortPredictions = allPredictions => Object.keys(allPredictions)
    .map(currentKey => ({
        type: currentKey,
        percent: allPredictions[currentKey],
    }))
    .sort((predA, predB) => predA.percent - predB.percent);

export default {
    predictMicroImage,
    predictMacroImage,
    sortPredictions,
};
