import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import Svg from 'common/Svg/Svg';
import styles from './RecordList.module.css';

const RecordListHeader = ({ className, patientView = false }) => {
    const classList = [styles.headerContainer, className].join(' ');

    return (
        <div className={classList}>
            { !patientView
                ? <span className={styles.colS}><Trans>Patient</Trans></span>
                : null }
            <span className={styles.colS}>
                <Trans>Date</Trans>
            </span>
            <span className={styles.colGrow}>
                <Trans>Supposed diagnosis</Trans>
            </span>
            <span className={styles.colGrow}>
                <Trans>Diagnosis</Trans>
            </span>
            <span className={styles.colIcon}>
                <Trans>Permission</Trans>
            </span>
        </div>
    );
};

// type Record = {
//     id: Number,
//     visit_id: Number,
//     visit_patientAgreement: Number,
//     patient_id: Number,
//     patient_name: String,
//     patient_description: String,
//     uptake: Date,
//     diagnosis: String,
//     suspicion: String,
//     md_id: Number,
//     md_name: String,
// };

const RecordListItem = ({ record, patientView = false }) => {
    const dateFormat = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(record.uptake).toLocaleDateString(navigator.language, dateFormat);

    const permissionIconName = record.visit_patientAgreement === 0
        ? 'icon-allperm'
        : record.visit_patientAgreement === 1
        ? 'icon-noface'
        : 'icon-noperm';

    const linkTarget = patientView
        ? `/patients/${record.patient_id}/visits/${record.visit_id}/${record.id}`
        : `/records/${record.id}`;

    return (
        <Link className={styles.link} to={linkTarget}>
            <div className={styles.itemContainer}>
                { !patientView ?
                <span className={styles.colS}>{record.patient_name}</span> : null }
                <span className={styles.colS}>{date}</span>
                <span className={styles.colGrow}>{ record.suspicion || '' }</span>
                <span className={styles.colGrow}>{ record.diagnosis || '' }</span>
                <span className={styles.colIcon}>
                    <Svg className={styles.svg} name={permissionIconName} size="25" />
                </span>
            </div>
        </Link>
    );
};

export { RecordListHeader, RecordListItem };
