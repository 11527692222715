import React from 'react';
import {withRouter} from 'react-router-dom';

import { Trans } from 'react-i18next';
import i18next from 'i18next';

import Gallery from 'react-grid-gallery';

import medicalRecordRepo from '~legacy/MedicalRecordRepo';
import imageRepo from "~legacy/ImageRepo";

import { css } from '@emotion/core';
import { HashLoader } from 'react-spinners';

import Button from 'common/Button/Button';
import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';

import styles from './Record.module.css';

const loader_style = css`
	position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: #4400ff; opacity: .8;
`;


class MedicalRecord extends React.Component {

	constructor(props) {
		super(props);

		const { match: { params } } = this.props;
		const recordId = params.recordId;

		this.state = {
			photos: [],
			recordId: recordId,
			currentImage: 0,
            busy: true
		}

		this.onSelectImage = this.onSelectImage.bind(this);

		this.createImageObjectFromDocument = this.createImageObjectFromDocument.bind(this);

		this.renderRecord = this.renderRecord.bind(this);
	}

	async componentDidMount() {
		medicalRecordRepo.getById(this.state.recordId, this.renderRecord);
	}

	createImageObjectFromContent(content) {
		return {
			src: "data:image;base64," + content.data,
            thumbnail: "data:image;base64," + content.data,
			detected: "",
			contentid: content.id,
			selected: false
		}
	}

	createImageObjectFromDocument(document) {
		const imageObj = this.createImageObjectFromContent(document.content);
		imageObj.documentid = document.id;

        if (document.predictions && (document.predictions.length > 0)){
			imageObj.predictionresult = document.predictions[document.predictions.length-1];
            imageObj.detected = i18next.t("bnoShort:" + imageObj.predictionresult.result, imageObj.predictionresult.result) + " (" + imageObj.predictionresult.probability + "%)";

            imageObj.tags = [{value: imageObj.detected, title: imageObj.detected}];
            imageObj.caption = "DH: " + imageObj.detected;
		}

		return imageObj;
	}

	renderRecord(recordParam) {
		var photos = []
		for (var i = 0; i < recordParam.documents.length; ++i) {
			const imageObj = this.createImageObjectFromDocument(recordParam.documents[i]);
			photos.push(imageObj);
		}

		this.setState(
			{patientId: recordParam.patient_id, patientName: recordParam.patient_name, patientDescription: recordParam.patient_description,
			uptake: recordParam.uptake,
			supposedDiagnosis: recordParam.suspicion, histologyResults: recordParam.diagnosis,
			photos: photos,
			visitId: recordParam.visit_id,
			allowedToDownload: (recordParam.visit_patientAgreement !== 2),
            busy: false},
		);
	}

    onSelectImage (index, image) {
        var images = this.state.photos.slice();
        var img = images[index];
        if (img.hasOwnProperty("isSelected"))
            img.isSelected = !img.isSelected;
        else
            img.isSelected = true;

        this.setState({
            photos: images
        });
    }

	back() {
		this.props.history.push('/records');
	}

	goToPatient() {
		this.props.history.push('/patients/' + this.state.patientId);
	}

	getSelectedImageIds() {
		var imgIds = [];

		let photos = this.state.photos;
		for (var i = 0; i < photos.length; ++i) {
			if (photos[i].isSelected) {
				imgIds.push(photos[i].contentid);
			}
		}

		return imgIds;
	}

	downloadSelectedImages() {
		imageRepo.downloadByIds(this.getSelectedImageIds());
	}

	downloadAllImages() {
		imageRepo.downloadAllByRecordId(this.state.recordId);
	}

	openRecordInVisit() {
        const { patientId, visitId, recordId } = this.state;
        this.props.history.push(`/patients/${patientId}/visits/${visitId}/${recordId}`);

	}

	render() {
		const TEXT_COLLAPSE_OPTIONS = {
		  collapse: false, // default state when component rendered
		  collapseText: '... ' + i18next.t('show more'), // text to show when collapsed
		  expandText: i18next.t('show less'), // text to show when expanded
		  minHeight: 100, // component height when closed
		  maxHeight: 250, // expanded to
		  textStyle: { // pass the css for the collapseText and expandText here
			color: "blue",
			fontSize: "20px"
		  }
		}

		const allowedStyle = {
			backgroundColor: "white"
		};

		const forbiddenStyle = {
			backgroundColor: "red"
		};

		return (
            <ScreenContent>
                <ScreenTitle>
                    <Trans>Record details</Trans>
                </ScreenTitle>

                <ScreenControls className={styles.controlsContainer}>
                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => this.back()}>
                            <Trans>Medical records</Trans>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div>
                        <Button className={styles.button} wide onClick={e => this.goToPatient()}>
                            <Trans>Go to patient</Trans>
                        </Button>
                        <Button className={styles.button} wide primary onClick={e => this.openRecordInVisit()}>
                            <Trans>Open visit</Trans>
                        </Button>
                    </div>
                </ScreenControls>

                <h3 className={styles.patientName}>
                    { this.state.patientName }
                </h3>
                <p className={styles.patientDescription}>
                    { this.state.patientDescription }
                </p>

                <div className='sweet-loading'>
					<HashLoader
					  css={loader_style}
					  sizeUnit={"px"}
					  size={150}
					  color={'#4400ff'}
					  loading={this.state.busy}

					/>
				</div>

                <div className={styles.imagesContainer}>
                    { this.state.busy
                        ? null
                        : this.state.allowedToDownload
                        ? (
                            <div className={styles.imagesControls}>
                                <Button
                                    wide
                                    className={styles.button}
                                    disabled={!this.state.allowedToDownload || (this.getSelectedImageIds().length === 0)}
                                    onClick={() => {this.downloadSelectedImages()}}
                                >
                                    <Trans>Download selected images</Trans>
                                </Button>
                                <Button
                                    wide
                                    className={styles.button}
                                    disabled={!this.state.allowedToDownload || (this.state.photos.length === 0)}
                                    onClick={() => {this.downloadAllImages()}}
                                >
                                    <Trans>Download all images</Trans>
                                </Button>
                            </div>
                        ) : (
                            <div className={styles.imagesControls}>
                                <label><Trans>The patient has forbidden to use these images</Trans>!</label>
                            </div>
                        )
                    }

                    <p className={styles.text}>
                        <span className={styles.textLabel}>
                            <Trans>Supposed diagnosis</Trans>:
                        </span>
                        <span>
                            { this.state.supposedDiagnosis }
                        </span>
                    </p>

                    <p className={styles.text}>
                        <span className={styles.textLabel}>
                            <Trans>Histology</Trans>:
                        </span>
                        <span>
                            { this.state.histologyResults }
                        </span>
                    </p>

                    <div
                        style={
                            this.state.allowedToDownload
                            ? { ...allowedStyle }
                            : { ...forbiddenStyle }
                        }	>
                        <div style={{
                                display: "block",
                                minHeight: "1px",
                                width: "100%",
                                border: "1px solid #ddd",
                                overflow: "auto"}}>
                            <Gallery
                                images={this.state.photos}
                                enableLightbox={true}
                                enableImageSelection={true}
                                onSelectImage={this.onSelectImage}
                            />
                        </div>
                    </div>
                </div>

            </ScreenContent>
		);
	}
}

export default withRouter(MedicalRecord);
