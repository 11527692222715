import React from 'react';
import ImageGaleryItem from './ImageGaleryItem';
import FullscreenGalery from './FullscreenGalery';
import styles from './ImageGalery.module.css';

class ImageGalery extends React.Component {

    state = {
        currentPosition: 0,
        fullscreenGalery: false,
    };

    onNext = () => {
        const { images } = this.props;
        const { currentPosition } = this.state;

        const newPosition = currentPosition === images.length - 1 ? 0 : currentPosition + 1;
        this.setState(currentState => ({ ...currentState, currentPosition: newPosition }));
    };

    onPrev = () => {
        const { images } = this.props;
        const { currentPosition } = this.state;

        const newPosition = currentPosition === 0 ? images.length - 1 : currentPosition - 1;
        this.setState(currentState => ({ ...currentState, currentPosition: newPosition }));
    };

    openFullscreenGalery = (e, targetPosition) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('click event on image', targetPosition);

        this.setState(currentState => ({ ...currentState, fullscreenGalery: true, currentPosition: targetPosition }));
    };

    closeFullscreenGalery = () => {
        this.setState(currentState => ({ ...currentState, fullscreenGalery: false, currentPosition: 0 }));
    };

    render() {
        const { images } = this.props;
        const { fullscreenGalery, currentPosition } = this.state;

        return (
            <div className={styles.container}>
                { images.map((img, index) => (
                    <ImageGaleryItem key={img.id} className={styles.image} imageData={img} onClick={(e) => this.openFullscreenGalery(e, index)} />
                ))}
                { fullscreenGalery
                    ? <FullscreenGalery image={images[currentPosition]} onNext={this.onNext} onPrev={this.onPrev} onClose={this.closeFullscreenGalery} />
                    : null
                }
            </div>
        );
    }
};

export default ImageGalery;
