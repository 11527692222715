import React from 'react';
import i18next from 'i18next';
import predictionSrvc from 'common/prediction.srvc';

import styles from './withDetailsOverlay.module.css';

const DetailsOverlay = ({ openImage, imageData }) => {

    const predictions = imageData.predictionResult ? imageData.predictionResult.allPredictions : {};
    const sortedPredictions = predictionSrvc.sortPredictions(predictions);

    const primaryPrediction = sortedPredictions.pop();
    const secondaryPrediction = sortedPredictions.pop();

    // TODO clean up later

    const primaryPredictionText = primaryPrediction ?
        `${i18next.t("bnoShort:" + primaryPrediction.type, primaryPrediction.type)} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
        `${i18next.t("bnoShort:" + secondaryPrediction.type, secondaryPrediction.type)} ${Math.round(secondaryPrediction.percent * 100)}%`: null;

    /*const primaryPredictionText = primaryPrediction ?
        `${primaryPrediction.type} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
        `${secondaryPrediction.type} ${Math.round(secondaryPrediction.percent * 100)}%`: null;
        */

    return (
        <div className={styles.overlayControls} onClick={openImage}>
            <div className={styles.topBar}>
                <span className={styles.label}>
                    { primaryPredictionText }
                </span>
                <span className={styles.label}>
                    { secondaryPredictionText }
                </span>
            </div>
            <div className={styles.bottomBar}>
                <span className={styles.label}>
                    { imageData.type === 'skinMicroPic' ? 'micro' : 'macro' }
                </span>
            </div>
        </div>
    );
};

const withDetailsOverlay = WrappedComponent => (props) => {
    const {
        onClick, imageData, className, ...wrappedComponentProps
    } = props;

    return (
        <div className={className}>
            <DetailsOverlay openImage={onClick} imageData={imageData} />
            <WrappedComponent {...wrappedComponentProps} />
        </div>
    );
}

export default withDetailsOverlay;
