import patientRepo from '~legacy/PatientRepo';

/**
 * Temporary wrapper over the callback based patientRepo untill the logic is migrated here.
 * With the patient service wrapper, Promises and async functions can be utilized in the codebase.
 */

const getPatients = () => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    const onError = error => reject(error);

    patientRepo.list(onSuccess, onError);
});

const getPatientById = (id) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    const onError = error => reject(error);

    patientRepo.getById(id, onSuccess, onError);
})

const searchByName = (name) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    const onError = error => reject(error);

    patientRepo.listByName(name, onSuccess, onError);
});

const createPatient = (patient) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    const onError = error => reject(error);

    patientRepo.createPatient(patient, onSuccess, onError);
});

const updatePatient = (patient) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    const onError = error => reject(error);

    patientRepo.updatePatient(patient, onSuccess, onError);
});

export default {
    getPatients,
    getPatientById,
    searchByName,
    createPatient,
    updatePatient,
};
