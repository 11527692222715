import { useState } from 'react';
import recordsSrvc from './Records.srvc';

function useRecords() {
    const [state, setState] = useState({
        records: [],
        getRecordsPending: false,
        getRecordsError: false,
    });

    async function getRecords(params = {}) {
        setState(prevState => ({ ...prevState, getRecordsPending: true, getRecordsError: false }));

        try {
            const response = await recordsSrvc.getRecords(params);
            setState(prevState => ({ ...prevState, getRecordsPending: false, records: response }));
        } catch {
            setState(prevState => ({ ...prevState, getRecordsPending: false, getRecordsError: true }));
        }
    };

    return [state, {
        getRecords,
    }];
}

export { useRecords };
