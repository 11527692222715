import React from 'react';
import styles from './ImageGaleryItem.module.css';

const ImageGaleryItem = ({ imageData, className, onClick }) => {
    const containerClasses = className ? [styles.container, className].join(' ') : styles.container;

    const imageSource = imageData.content.data.slice(0,4) === 'blob'
        ? imageData.content.data
        : `data:image;base64,${imageData.content.data}`;

    return (
        <div className={containerClasses}>
            <img
                className={styles.image}
                onClick={onClick}
                src={imageSource}
                alt="temp"
            />
        </div>
    );
};

export default ImageGaleryItem;
