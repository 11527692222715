import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom'
import i18next from 'i18next';

import { useRedirect } from 'common/common.hooks';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import SearchField from 'common/SearchField/SearchField';
import ItemList from 'common/ItemList/ItemList';
import { PatientsContext } from 'modules/Patients/Patients.context';
import PatientListItem from './PatientListItem';
import styles from './PatientList.module.css';

const Patients = () => {
    const { patientsState, patientsActn } = useContext(PatientsContext);
    const [ redirectState, redirectActn ] = useRedirect();

    const onAddItem = () => redirectActn.setLocation('/patients/create');

    useEffect(() => {
        patientsActn.getPatients();
    }, []);

    return redirectState.location ? <Redirect to={redirectState.location} push /> : (
        <ScreenContent>
            <SearchField
                className={styles.searchField}
                icon="search"
                placeholder={i18next.t('Search patients')}
                onChange={searchString => patientsActn.searchByName(searchString)}
            />
            <ItemList className={styles.patientList} addItemText={i18next.t('Add patient')} addItemAction={onAddItem}>
                { patientsState.patients.map(patient => <PatientListItem key={patient.id} patient={patient} />) }
            </ItemList>
        </ScreenContent>
    );
};

export default Patients;
