import medicalRecordRepo from '~legacy/MedicalRecordRepo';

/**
 * Temporary wrapper over the callback based medicalRecordRepo untill the logic is migrated here.
 * With the records service wrapper, Promises and async functions can be utilized in the codebase.
 */

// type Record = {
//     id: Number,
//     visit_id: Number,
//     visit_patientAgreement: Number,
//     patient_id: Number,
//     patient_name: String,
//     patient_description: String,
//     uptake: Date,
//     diagnosis: String,
//     suspicion: String,
//     md_id: Number,
//     md_name: String,
// };

const getRecords = (params) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    medicalRecordRepo.list(onSuccess, params);
});

const getRecordById = (id) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    medicalRecordRepo.getById(id, onSuccess);
});

export default {
    getRecords,
    getRecordById,
};
