import React from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';

import { useRedirect } from 'common/common.hooks';
import { VisitProviderWrapper } from 'modules/Visit/Visit.context';

import VisitDetails from './VisitDetails/VisitDetails';
import DocumentsEditor from './DocumentsEditor/DocumentsEditor';
import LesionRecordEditor from './RecordEditor/LesionRecordEditor';

const Visit = ({ match, location, history }) => {
    const [redirectState, redirectActn] = useRedirect();

    console.log('match', match);
    console.log('location', location);
    console.log('history', history);

    return redirectState.location
        ? <Redirect to={redirectState.location} push />
        : (
        <VisitProviderWrapper>
            <Switch>
                <Route exact path={`${match.path}/create`} component={VisitDetails} />

                <Route exact path={`${match.path}/create/records/create`} component={LesionRecordEditor} />
                <Route exact path={`${match.path}/create/records/:recordId`} component={LesionRecordEditor} />
                <Route exact path={`${match.path}/create/documents`} component={DocumentsEditor} />

                <Route exact path={`${match.path}/:visitId/records/create`} component={LesionRecordEditor} />
                <Route exact path={`${match.path}/:visitId/records/:recordId`} component={LesionRecordEditor} />
                <Route exact path={`${match.path}/:visitId/documents`} component={DocumentsEditor} />

                <Route exact path={`${match.path}/:visitId/:recordId?`} component={VisitDetails} />
            </Switch>
        </VisitProviderWrapper>
        );
};

export default withRouter(Visit);
