import React from 'react';
import { Trans } from 'react-i18next';
import { Route } from 'react-router-dom';
import auth0Client from '../../Auth';


function SecuredRoute(props) {
  const { component: Component, path, checkingSession, exact } = props;
  return (
    <Route exact={exact} path={path} render={() => {
      if (checkingSession) return <h3 className="text-center"><Trans>Validating session</Trans>…</h3>;
      if (!auth0Client.isAuthenticated()) {
        auth0Client.signIn();
        return <div></div>;
      }
      return <Component />
    }} />
  );
}

export default SecuredRoute;
