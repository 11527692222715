import React from 'react';
import styles from './ScreenContent.module.css';

// TODO: this component could handle displaying the loading indicator when data is not ready

const ScreenContent = ({ children }) => (
    <div className={styles.outerContainer}>
        <div className={styles.innerContainer}>
            { children }
        </div>
    </div>
);

export default ScreenContent;
