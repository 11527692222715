import React from 'react';
import Svg from 'common/Svg/Svg';
import styles from './Button.module.css';

const Button = ({ primary = false, disabled = false, wide = false, children, icon, onClick, className }) => {
    const buttonStyle = primary ? styles.buttonPrimary : styles.buttonSecondary;
    const widthControl = wide ? styles.mwWide : styles.mwNromal;
    const classList = [buttonStyle, widthControl, className].join(' ');

    return (
        <button className={classList} disabled={disabled} onClick={onClick}>
            { icon ? <Svg className={styles.svg} name={icon} size="30" /> : null }
            <span className={styles.text}>{ children }</span>
        </button>
    );
};

export default Button;
