import React, { useEffect, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import { useRedirect } from 'common/common.hooks';
import { VisitContext } from 'modules/Visit/Visit.context';

import Button from 'common/Button/Button';
import LoadingIndicator from 'common/LoadingIndicator/LoadingIndicator';
import ScreenContent from 'common/ScreenContent/ScreenContent';
import ScreenTitle from 'common/ScreenTitle/ScreenTitle';
import ScreenControls from 'common/ScreenControls/ScreenControls';
import { Breadcrumb, BreadcrumbItem } from 'common/Breadcrumb/Breadcrumb';
import ImageDropzone from 'common/ImageDropzone/ImageDropzone';
import DocumentImage from 'modules/Visit/DocumentsEditor/DocumentImage';


import styles from './DocumentsEditor.module.css';

// ------------------------------------------------------------------------------------------------------------------------ DocumentsEditor component --

const DocumentsEditor = ({ match, location, history }) => {
    const { patientId, visitId, recordId } = match.params;
    const [redirectState, redirectActn] = useRedirect();

    const {
        visitState, visitActn, patientState, patientActn,
    } = useContext(VisitContext);

    // ----------------------------------------------------------------------------------------------------------------------------- init local state --

    console.log('documents data', visitState);

    const [localState, setLocalState] = useState({
        documents: [],
        newDocuments: [],
    });

    // ----------------------------------------------------------------------------------------------------------------------------------- UI actions --

    const onSave = () => {
        const { documents, newDocuments } = localState;

        const redirectLocation = visitId
            ? `/patients/${patientId}/visits/${visitId}`
            : `/patients/${patientId}/visits/create`;

        visitActn.updateDocuments({ documents, newDocuments });
        redirectActn.setLocation(redirectLocation);
    };

    const onCancel = () => visitId
        ? redirectActn.setLocation(`/patients/${patientId}/visits/${visitId}`)
        : redirectActn.setLocation(`/patients/${patientId}/visits/create`);

    const onDeleteAll = () => {
        const isConfirmed = window.confirm(i18next.t('Please confirm delete operation'));

        const redirectLocation = visitId
            ? `/patients/${patientId}/visits/${visitId}`
            : `/patients/${patientId}/visits/create`;

        if (isConfirmed) {
            visitActn.updateDocuments({ documents: [], newDocuments: [] });
            redirectActn.setLocation(redirectLocation);
        }
    }

    const onAddImages = ({ imageFiles }) => {
        console.log('image files added', imageFiles);

        const documentImages = imageFiles.map(file => ({
            id: Date.now() + Math.floor(Math.random() * 1000000),
            creationTime: new Date().toISOString(),
            type: "otherDoc",
            content: {
                id: null,
                data: URL.createObjectURL(file),
                handler: null,
                hibernateLazyInitializer: null,
            },
            predictions: [],
            file,
        }));

        setLocalState(prevState => ({
            ...prevState,
            documents: [...prevState.documents],
            newDocuments: [...prevState.newDocuments, ...documentImages],
        }));
    };

    // TODO: remove later if no "bulk" remove is required on UI
    const onRemoveImages = ({ images }) => {
        const { documents, newDocuments } = localState;

        const updatedDocuments = documents.filter(currentDocument => {
            const toRemove = images.find(imageItem => imageItem.id === currentDocument.id);
            return !toRemove;
        });

        const updatedNewDocuments = newDocuments.filter(currentDocument => {
            const toRemove = images.find(imageItem => imageItem.id === currentDocument.id);
            return !toRemove;
        });

        setLocalState(prevState => ({
            ...prevState,
            documents: updatedDocuments,
            newDocuments: updatedNewDocuments,
        }));
    };

    const onRemoveImage = ({ image }) => {
        onRemoveImages({ images: [image] });
    };

    // -------------------------------------------------------------------------------------------------------------------------------------- effects --

    useEffect(() => {
        if (patientId && patientState.patient.id !== Number(patientId)) patientActn.getPatientById(patientId);
        if (visitId && visitState.visit.id !== Number(visitId)) visitActn.getVisitById(visitId);
    }, []);

    useEffect(() => {
        const documentsCopy = visitState.visit.documents.map(visitId => visitState.documents.byId[visitId]);
        const newDocumentsCopy = visitState.visit.newDocuments.map(visitId => visitState.documents.byId[visitId]);

        setLocalState(prevState => ({ ...prevState, documents: documentsCopy, newDocuments: newDocumentsCopy }));
    }, [visitState]);

    // ------------------------------------------------------------------------------------------------------------------------ prepare for rendering --

    const currentDocuments = [...localState.documents, ...localState.newDocuments];

    const backToVisitLocation = visitId
        ? `/patients/${patientId}/visits/${visitId}`
        : `/patients/${patientId}/visits/create`;

    console.log('documents editor local state', localState);
    console.log('current documents', currentDocuments);

    // ------------------------------------------------------------------------------------------------------------------------------------ rendering --

    return redirectState.location
    ? <Redirect to={redirectState.location} push />
    : patientState.getPatientPending || visitState.getVisitPending
    ? <LoadingIndicator />
    : (
        <ScreenContent>
            <ScreenTitle>
                <Trans>Documents</Trans>
            </ScreenTitle>
            <ScreenControls className={styles.controlsContainer}>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation('/patients')}>
                        <Trans>Patients</Trans>
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(`/patients/${patientId}`)}>
                        { patientState.patient.name }
                    </BreadcrumbItem>
                    <BreadcrumbItem onClick={() => redirectActn.setLocation(backToVisitLocation)}>
                        <Trans>Visit</Trans>
                    </BreadcrumbItem>
                </Breadcrumb>
                <div>
                    { currentDocuments.length > 0 ?
                    <Button onClick={e => onDeleteAll()}>
                        <Trans>Delete All</Trans>
                    </Button> : null }
                    <Button className={styles.button} onClick={e => onCancel()}>
                        <Trans>Discard</Trans>
                    </Button>
                    <Button className={styles.button} primary onClick={e => onSave()}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </ScreenControls>

            <ImageDropzone onAddImages={onAddImages}>
            { currentDocuments.map(imageItem => (
                <DocumentImage
                    key={imageItem.id}
                    imageData={imageItem}
                    onDelete={onRemoveImage}
                />
            ))}
            </ImageDropzone>

        </ScreenContent>
    );
};

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default DocumentsEditor;
