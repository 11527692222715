import axios from 'axios';
import auth0Client from '../Auth';

const usersUrl = '/webapi/users/';

class UserRepo {
	constructor() {
		this.list = this.list.bind(this);
		this.getById = this.getById.bind(this);
		this._getFromApi = this._getFromApi.bind(this);
	}
	
	list(callBack, errorCallback) {	
	  this._getFromApi(usersUrl, callBack, errorCallback);
	}
	
	getById(id, callBack) {	
		const url = usersUrl + 'id/' + id + '/';
		this._getFromApi(url, callBack, null);
	}

	_getFromApi(url, callBack, errorCallback){
		const authStr = 'Bearer '.concat(auth0Client.accessToken);
	  axios.get(url,{ headers: { Authorization: authStr } }).then(
		  (response) => {				
				callBack(response.data);
		  },	
			(error) => { 
				console.log(error);
				if (errorCallback) {
					errorCallback(error);
				}
			}		
	  )
	}

	
}

const userRepo = new UserRepo();

export default userRepo;