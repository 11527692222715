import React, { Component } from 'react';
import { Offline } from "react-detect-offline";
import { Trans, withNamespaces } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Switch, Route, withRouter } from 'react-router-dom';
import auth0Client from './Auth';
import Callback from './Callback';
import NavBar from './common/NavBar/NavBar';
import SecuredRoute from './common/SecuredRoute/SecuredRoute';

import Patients from 'modules/Patients/Patients';
import Visit from 'modules/Visit/Visit';
import Records from 'modules/Records/Records';
import Record from 'modules/Records/Record/Record';
import QuizLauncher from './modules/Quiz/QuizLauncher';

import "react-datepicker/dist/react-datepicker.css";
import styles from './App.module.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    }
  }

  async componentDidMount() {
    if (this.props.location.pathname === '/callback') {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
    this.setState({ checkingSession: false });
  }

  render() {
    const polling = {
      url: '/',
      interval: 30000
    };

    return (
      <div className={styles.container}>
        <NavBar />
        <Offline polling={polling}><Trans>Not connected</Trans>.</Offline>

        {/* THE ORDER OF ROUTES IS IMPORTANT INSIDE THE SWITCH, because the pattern matching! Modify with caution! */}

        <Switch>
          <Route exact path='/callback' component={Callback} />
          <Route exact path='/quiz' component={QuizLauncher} />

          <SecuredRoute path='/patients/:patientId/visits' component={Visit} checkingSession={this.state.checkingSession} />
          <SecuredRoute path='/patients' component={Patients} checkingSession={this.state.checkingSession} />

          <SecuredRoute exact path='/records' component={Records} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/records/:recordId' component={Record} checkingSession={this.state.checkingSession} />
        </Switch>

        <NotificationContainer />
      </div>
    );
  }
}

export default withNamespaces()(withRouter(App));
