import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import quizRepo from './QuizRepo';
import Quiz from 'common/Quiz/Quiz';

import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class QuizLauncher extends Component {
	constructor(props) {
		super(props);

		this.state = {
			quiz :  {
				"quizTitle": <Trans>Skin Lesion Quiz</Trans>,
				"quizSynopsis": <Trans>Try to categorize the upcoming pictures</Trans>,
				"questions": []
			}
		};

		this.renderQuiz = this.renderQuiz.bind(this);
		this.displayError = this.displayError.bind(this);
	}

    async componentDidMount() {
		quizRepo.getQuestions(this.renderQuiz, null);
    }

	focusInput = (component) => {
		if (component) {
			component.focus();
		}
	}

	renderQuiz(quizParam) {
		if ((!quizParam.questions) || (quizParam.questions.length == 0)){
			this.displayError();
		}
		this.setState({quiz: quizParam});
	}

	displayError(error) {
		NotificationManager.error(i18next.t('Error'), i18next.t('Error text'), 5000, null, true);
	}

    render() {
		const quiz = this.state.quiz;

		return (
		<div>
			{
			((quiz.questions) && (quiz.questions.length > 0)) &&
				<div>
					<Quiz quiz={quiz} shuffle={true} showInstantFeedback={true} continueTillCorrect={false} customResultText={quiz.aiResult}/>
				</div>
			}
			{/* <button
				onClick={() => {quizRepo.getQuestions(this.renderQuiz, null)}}>
				<Trans>Generate New Quiz</Trans>
			</button> */}
		</div>
		)
  	}
}

export default withRouter(QuizLauncher);