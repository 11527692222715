import i18n from '../i18n';

export const longBnoCodesForDropDownAssistance = [
	"B07 " + i18n.t("bnoLong:B07", "B07"),
    "BCC " + i18n.t("bnoLong:BCC", "BCC"),
	"C43 " + i18n.t("bnoLong:C43", "C43"),
	"C44 " + i18n.t("bnoLong:C44", "C44"),
	"C77 " + i18n.t("bnoLong:C77", "C77"),
	"C79 " + i18n.t("bnoLong:C79", "C79"),
	"D03 " + i18n.t("bnoLong:D03", "D03"),
	"D04 " + i18n.t("bnoLong:D04", "D04"),
	"D18 " + i18n.t("bnoLong:D18", "D18"),
	"D22 " + i18n.t("bnoLong:D22", "D22"),
	"D23 " + i18n.t("bnoLong:D23", "D23"),
	"L30 " + i18n.t("bnoLong:L30", "L30"),
	"L43 " + i18n.t("bnoLong:L43", "L43"),
	"L57 " + i18n.t("bnoLong:L57", "L57"),
	"L72 " + i18n.t("bnoLong:L72", "L72"),
	"L82 " + i18n.t("bnoLong:L82", "L82"),
	"L85 " + i18n.t("bnoLong:L85", "L85"),
	"L87 " + i18n.t("bnoLong:L87", "L87"),
	"L90 " + i18n.t("bnoLong:L90", "L90"),
	"L98 " + i18n.t("bnoLong:L98", "L98")
];

export default {
    bnocodes: longBnoCodesForDropDownAssistance,
}
