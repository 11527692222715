import React from 'react';
import styles from './ScreenControls.module.css'

const ScreenControls = ({ children, className }) => {
    const containerClasses = [styles.controlsContainer, className].join(' ');

    return (
        <div className={containerClasses}>
            { children }
        </div>
    );
}

export default ScreenControls;
