import { useState } from 'react';
import EXIF from 'exif-js';
import predictionSrvc from 'common/prediction.srvc';

// -------------------------------------------------------------------------------------------------------------------------------- LesionRecord hook --

function useLesionRecord() {
    const initState = {
        medicalRecord: {
            id: Date.now() + Math.floor(Math.random() * 1000000),
            type: "seSkin",
            uptake: new Date(),
            suspicion: "",
            diagnosis: "",
            documents: [],
            newDocuments: [],
        },
        images: [],
        pendingPredictions: [],
    };

    const [state, setState] = useState(initState);

    function reInitalize({ medicalRecord = initState, images = [] }) {
        setState({ medicalRecord, newImages: [], pendingPredictions: [], images });
    }

    // ------------------------------------------------------------------------------------------------------------------------ update record details --

    function updateSuspicion({ suspicion }) {
        setState(prevState => ({
            ...prevState,
            medicalRecord: {
                ...prevState.medicalRecord,
                suspicion,
            },
        }));
    }

    function updateDiagnosis({ diagnosis }) {
        setState(prevState => ({
            ...prevState,
            medicalRecord: {
                ...prevState.medicalRecord,
                diagnosis,
            },
        }));
    }

// --------------------------------------------------------------------------------------------------------------------------------------- add images --

    async function createImageObjects({ imageFiles }) {
        const imageObjects = await Promise.all(imageFiles.map(async file => {
            const withExif = await new Promise((resolve, reject) => {
                EXIF.getData(file, () => resolve(file));
            });

            const imageType = file.exifdata.FocalLength == 0 || file.exifdata.FNumber == 0
                ? 'skinMicroPic'
                : 'skinMacroPic';

            console.log('image file with exif data', withExif);

            return {
                id: Date.now() + Math.floor(Math.random() * 1000000),
                creationTime: new Date().toISOString(),
                type: imageType,
                content: {
                    id: null,
                    data: URL.createObjectURL(file),
                    handler: null,
                    hibernateLazyInitializer: null,
                },
                predictionResult: null,
                file,
            };
        }));

        return imageObjects;
    }

    async function getPrediction({ imageFile, type }) {
        const prediction = type === 'skinMicroPic'
            ? await predictionSrvc.predictMicroImage({ image: imageFile })
            : await predictionSrvc.predictMacroImage({ image: imageFile });

        return prediction;
    }

    async function addImages({ imageFiles }) {
        const imageObjects = await createImageObjects({ imageFiles });
        const newImageIds = imageObjects.map(imageObject => imageObject.id);
        const { medicalRecord } = state;

        setState(prevState => ({
            ...prevState,
            medicalRecord: {
                ...medicalRecord,
                newDocuments: [...medicalRecord.newDocuments, ...newImageIds]
            },
            images: [...prevState.images, ...imageObjects],
            pendingPredictions: [...prevState.pendingPredictions, ...newImageIds],
        }));

        const predictionPromises = imageObjects.map(async imgItem => {
            const prediction = await getPrediction({ imageFile: imgItem, type: imgItem.type });
            return { imageId: imgItem.id, prediction };
        });

        const predictions = await Promise.all(predictionPromises);

        setState(prevState => {
            const updatedImageObjects = predictions.map(predictionItem => {
                const { prediction, imageId } = predictionItem;
                const imageObject = imageObjects.find(imgItem => imgItem.id === imageId);
                return {
                    ...imageObject,
                    predictionResult: prediction,
                };
            });

            const updatedPendingPredictions = prevState.pendingPredictions.filter(imgId => !predictions.find(predItem => predItem.imageId === imgId));
            const filteredNewImages = prevState.images.filter(imageItem => !predictions.find(predItem => predItem.imageId === imageItem.id));

            return {
                ...prevState,
                images: [...filteredNewImages, ...updatedImageObjects],
                pendingPredictions: [...updatedPendingPredictions],
            };
        });
    }

    // TODO: check when the function is called that the new imageType is not tha same as the current type!
    async function updateImageType({ imageId, imageType }) {
        const imageItem = state.images.find(imageItem => imageItem.id === imageId);
       
        setState(prevState => ({
            ...prevState,
            pendingPredictions: [...prevState.pendingPredictions, imageId],
        }));

        const prediction = await getPrediction({ imageFile: imageItem, type: imageType });

        const updatedImageItem = {
            ...imageItem,
            type: imageType,
            predictionResult: prediction,
        };

        setState(prevState => {
            const { images, pendingPredictions } = prevState;
            const targetImageIndex = images.indexOf(images.find(imageItem => imageItem.id === imageId));

            const newImages = [
                ...images.slice(0, targetImageIndex),
                updatedImageItem,
                ...images.slice(targetImageIndex + 1),
            ];

            const updatedPendingPredictions = pendingPredictions.filter(currentId => currentId !== imageId);

            return {
                ...prevState,
                images: newImages,
                pendingPredictions: [...updatedPendingPredictions],
            };
        });
    }

    // async function addImages({ imageFiles }) {
    //     const imageObjects = await createImageObjects({ imageFiles });
    //     const newImageIds = imageObjects.map(imageObject => imageObject.id);

    //     setState(prevState => ({
    //         ...prevState,
    //         newImages: [...prevState.newImages, ...imageObjects],
    //         pendingPredictions: [...prevState.pendingPredictions, ...newImageIds],
    //     }));

    //     const predictions = await getPredictions({ images: imageObjects });

    //     setState(prevState => {
    //         const updatedImageObjects = predictions.map(predictionItem => {
    //             const { prediction, imageId } = predictionItem;
    //             const imageObject = imageObjects.find(imgItem => imgItem.id === imageId);
    //             return {
    //                 ...imageObject,
    //                 predictions: [prediction],
    //             };
    //         });

    //         const updatedPendingPredictions = prevState.pendingPredictions.filter(imgId => !predictions.find(predItem => predItem.imageId === imgId));
    //         const filteredNewImages = prevState.newImages.filter(imageItem => !predictions.find(predItem => predItem.imageId === imageItem.id));

    //         return {
    //             ...prevState,
    //             newImages: [...filteredNewImages, ...updatedImageObjects],
    //             pendingPredictions: [...updatedPendingPredictions],
    //         };
    //     });
    // }

// ------------------------------------------------------------------------------------------------------------------------------------ remove images --

    function removeImages({ images }) {
        const { medicalRecord: currentMDRecord, images: currentImages } = state;

        const updatedMDRecordImages = currentMDRecord.documents.filter(currentId => {
            const toRemove = images.find(imageItem => imageItem.id === currentId);
            return !toRemove;
        });

        const updatedNewMDRecordImages = currentMDRecord.newDocuments.filter(currentId => {
            const toRemove = images.find(imageItem => imageItem.id === currentId);
            return !toRemove;
        });

        const updatedImages = currentImages.filter(currentImage => {
            const toRemove = images.find(imageItem => imageItem.id === currentImage.id);
            return !toRemove;
        });

        setState((prevState) => {
            return ({
                ...prevState,
                medicalRecord: {
                    ...prevState.medicalRecord,
                    documents: updatedMDRecordImages,
                    newDocuments: updatedNewMDRecordImages,
                },
                images: updatedImages,
            });
        });
    }

    // function removeImages({ images }) {
    //     const { medicalRecord: currentMDRecord, newImages: currentImages } = state;

    //     const updatedMDRecordImages = currentMDRecord.documents.filter(currentId => {
    //         const toRemove = images.find(imageItem => imageItem.id === currentId);
    //         return !toRemove;
    //     });

    //     const updatedNewMDRecordImages = currentMDRecord.newDocuments.filter(currentId => {
    //         const toRemove = images.find(imageItem => imageItem.id === currentId);
    //         return !toRemove;
    //     });

    //     const updatedCurrentImages = currentImages.filter(currentImage => {
    //         const toRemove = images.find(imageItem => imageItem.id === currentImage.id);
    //         return !toRemove;
    //     });

    //     setState((prevState) => {
    //         return ({
    //             ...prevState,
    //             medicalRecord: {
    //                 ...prevState.medicalRecord,
    //                 documents: updatedMDRecordImages,
    //                 newDocuments: updatedNewMDRecordImages,
    //             },
    //             newImages: updatedCurrentImages,
    //         });
    //     });
    // }

    // ---------------------------------------------------------------------------------------------------------------------------- expose public API --

    return [state, {
        reInitalize,
        updateSuspicion,
        updateDiagnosis,
        addImages,
        removeImages,
        updateImageType,
    }];
}

// ------------------------------------------------------------------------------------------------------------------------------------------- expors --

export { useLesionRecord };
