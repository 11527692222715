import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import Svg from 'common/Svg/Svg';
import predictionSrvc from 'common/prediction.srvc';

import styles from './withControlsOverlay.module.css';

const InProgressOverlay = () => (
    <div className={styles.overlayProgress}>
        <p>
            <Trans>In progress</Trans>
        </p>
    </div>
);

const ControlsOverlay = ({ controlActions, imageData }) => {
    const microClass = imageData.type === 'skinMicroPic' ? styles.label : styles.labelInActive;
    const macroClass = imageData.type === 'skinMacroPic' ? styles.label : styles.labelInActive;

    const predictions = imageData.predictionResult ? imageData.predictionResult.allPredictions : {};
    const sortedPredictions = predictionSrvc.sortPredictions(predictions);

    const primaryPrediction = sortedPredictions.pop();
    const secondaryPrediction = sortedPredictions.pop();

    // TODO clean up later

    const primaryPredictionText = primaryPrediction ?
         `${i18next.t("bnoShort:" + primaryPrediction.type, primaryPrediction.type)} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
         `${i18next.t("bnoShort:" + secondaryPrediction.type, secondaryPrediction.type)} ${Math.round(secondaryPrediction.percent * 100)}%`: null;

/*
    const primaryPredictionText = primaryPrediction ?
        `${primaryPrediction.type} ${Math.round(primaryPrediction.percent * 100)}%`: null;

    const secondaryPredictionText = secondaryPrediction ?
        `${secondaryPrediction.type} ${Math.round(secondaryPrediction.percent * 100)}%`: null;
*/

    return (
        <div className={styles.overlayControls} onClick={controlActions.openImage}>
            <div className={styles.topBar}>
                <span className={styles.prediction}>
                    { primaryPredictionText }
                </span>
                <span className={styles.prediction}>
                    { secondaryPredictionText }
                </span>
                <button className={styles.deleteButton} onClick={controlActions.delete}>
                    <Svg className={styles.svg} name="icon-trashcan" size="30" />
                </button>
            </div>
            <div className={styles.bottomBar}>
                <span className={microClass} onClick={controlActions.setMicro}>
                    micro
                </span>
                <span className={macroClass} onClick={controlActions.setMacro}>
                    macro
                </span>
            </div>
        </div>
    );
};

const withControlsOverlay = WrappedComponent => (props) => {
    const {
        onUpdateImageType, onDelete, onClick, predictionPending, imageData, className, ...wrappedComponentProps
    } = props;

    const onDeleteWrapper = (e) => {
        e.stopPropagation();
        onDelete({ image: imageData });
    };

    const onSetMicro = (e) => {
        e.stopPropagation();
        onUpdateImageType({ imageId: imageData.id, imageType: 'skinMicroPic' });
    };

    const onSetMacro = (e) => {
        e.stopPropagation();
        onUpdateImageType({ imageId: imageData.id, imageType: 'skinMacroPic' })
    };

    const controlActions = {
        setMicro: onSetMicro,
        setMacro: onSetMacro,
        delete: onDeleteWrapper,
        openImage: onClick,
    };

    const activeOverlay = predictionPending
        ? <InProgressOverlay />
        : <ControlsOverlay controlActions={controlActions} imageData={imageData} />

    return (
        <div className={className}>
            { activeOverlay }
            <WrappedComponent {...wrappedComponentProps} />
        </div>
    );
}

export default withControlsOverlay;
