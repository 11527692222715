import React, { createContext } from 'react';
import { usePatients } from './Patients.hooks';

const PatientsContext = createContext();
const { Provider: PatientsProvider, Consumer: PatientsConsumer } = PatientsContext;

const PatientsProviderWrapper = ({ children }) => {

    const [patientsState, patientsActn] = usePatients();

    const providedValue = {
        patientsState,
        patientsActn,
    };

    return (
        <PatientsProvider value={providedValue}>
            { children }
        </PatientsProvider>
    );
};

export {
    PatientsContext,
    PatientsProvider,
    PatientsConsumer,
    PatientsProviderWrapper,
};
