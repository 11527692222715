import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from 'common/Svg/Svg';
import styles from './SearchField.module.css';

const SearchField = ({ icon, placeholder, onChange, className }) => {
    const containerClasses = [className, styles.container].join(' ');
    const inputRef = useRef(null);

    const focusInput = () => inputRef.current.focus();

    useEffect(focusInput, []);

    return (
        <div className={containerClasses} onClick={focusInput}>
            <Svg className={styles.svg} name={icon} size="30" />
            <input
                className={styles.input}
                type="text"
                placeholder={placeholder}
                ref={inputRef}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    );
}

SearchField.propTypes = {
    icon: PropTypes.string,
    placeholder: PropTypes.string,
};

export default SearchField;