import visitRepo from '~legacy/VisitRepo';
// import { getVisitResponse1 } from './Visit.mock';

// ------------------------------------------------------------------------------------------------------------------------------------------ helpers --

function normalizeMedicalRecords(medicalRecords) {

    const { normalizedMDRecords, normalizedMDImages } = medicalRecords.reduce((accumulator, currentMDRecord) => {
        const { normalizedMDRecords, normalizedMDImages } = accumulator;

        const { mdImages, mdImageIds } = currentMDRecord.documents.reduce((accumulator, currentImage) => {
            const { mdImages, mdImageIds } = accumulator;
            const { content, creationTime, id, predictions, type } = currentImage;

            const sortedPredictions = predictions.slice().sort((predA, predB) => Number(predA.id) - Number(predB.id));

            const transformedImage = {
                id,
                type,
                content,
                creationTime,
                predictionResult: sortedPredictions.pop(),
            };

            return {
                mdImages: [...mdImages, transformedImage],
                mdImageIds: [...mdImageIds, transformedImage.id],
            };
        }, { mdImages: [], mdImageIds: [] });

        const mdRecord = {
            ...currentMDRecord,
            documents: mdImageIds,
            newDocuments: [],
        };

        return {
            normalizedMDRecords: [...normalizedMDRecords, mdRecord],
            normalizedMDImages: [...normalizedMDImages, ...mdImages],
        };

    }, { normalizedMDRecords: [], normalizedMDImages: [] })

    console.log('>>>>>>>>> medical records', medicalRecords);
    console.log('>>>>>>>>> normalized medical records', normalizedMDRecords);
    console.log('>>>>>>>>> md record images', normalizedMDImages);

    return [normalizedMDRecords, normalizedMDImages];
}

const normalizeVisit = (visitData) => {
    console.log('>>>>>>>>>>>>>>> normalize visits response', visitData);

    const [medicalRecords, mdRecordImages] = normalizeMedicalRecords(visitData.medicalRecords);

    const visit = {
        id: visitData.id,
        date: visitData.date,
        permission: visitData.patientAgreement,
        mdId: visitData.md ? visitData.md.id : null,

        documents: visitData.documents.map(currentDocument => currentDocument.id),
        newDocuments: [],

        medicalRecords: medicalRecords.map(currentMDRecord => currentMDRecord.id),
        newMedicalRecords: [],
    };

    const normalizedVisitData = {
        visit,
        documents: visitData.documents,
        medicalRecords,
        mdRecordImages,
    };

    console.log('>>>>>>>>>>>>>>> normalized visits response', normalizedVisitData);

    return normalizedVisitData;
};

// ---------------------------------------------------------------------------------------------------------------------------------------- api stuff --

const getVisitById = (id) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(normalizeVisit(result));
    visitRepo.getById(id, onSuccess);
});

const deleteVisitById = (id) => new Promise((resolve, reject) => {
    const onSuccess = result => resolve(result);
    visitRepo.deleteById(id, onSuccess);
});

// already a promise thanks to axios
const saveVisit = (visitDto) => visitRepo.saveVisit(visitDto);

// ------------------------------------------------------------------------------------------------------------------------------------------ exports --

export default {
    getVisitById,
    deleteVisitById,
    saveVisit,
};
